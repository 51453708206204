import { ReactEventHandler } from "react"

function AddHoverBox({onClick}: {onClick: ReactEventHandler<HTMLDivElement>}) {

    return <div
        onClick={onClick}
        className="relative flex h-[12em] w-[18em] items-center justify-center rounded-[1.5em] border-[1px] border-gray-600 bg-transparent p-[1.5em] text-lime-300">
        <div
            className="cursor-pointer group absolute left-1/2 top-1/2 flex h-[5em] w-[5em] -translate-x-1/2 -translate-y-1/2 items-center justify-center overflow-hidden rounded-full border-[1px] border-[#ffffffaa] bg-[#8988885c] backdrop-blur-[6px] hover:scale-125 transition duration-700">
            <svg className="h-[1.7em] w-[1.7em] duration-300" xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960" fill="#fff"><path d="M520-400h80v-120h120v-80H600v-120h-80v120H400v80h120v120ZM320-240q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320Zm0-80h480v-480H320v480ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm160-720v480-480Z" /></svg>
        </div>
    </div>
}
export default AddHoverBox