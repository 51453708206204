import { useEffect, useState } from "react"
import { useMainStore } from "../store/main"
import _, { result } from 'lodash';

function Sort() {
  const {
    currentShop,
    allKeys, setAllKeys,
    setSort, clearSort, sort
  } = useMainStore<any>((state) => state)

  const data = currentShop.items ? currentShop.items.map((el: any) => {
    return el
  }) : (currentShop.sliceItems && currentShop.sliceItems.map((el: any) => {
    return el
  }))

  const excludedKeys = ['id', 'result', 'result_image', "image", 'cost'];

  const sanitizedData = data.map((el: any) => _.omit(el, excludedKeys));

  useEffect(() => {
    setAllKeys(_.uniq(_.flatMap(sanitizedData, _.keys)));
  }, [currentShop.items, currentShop.sliceItems, sort])

  const [selectedKeys, setSelectedKeys] = useState<any>({});

  const handleKeyChange = (key: any, value: any) => {

    setSelectedKeys((prevSelectedKeys: any) => {
      const updatedSelection = { ...prevSelectedKeys };

      if (value === '') {
        delete updatedSelection[key];
      } else {
        updatedSelection[key] = value;
      }

      // Фильтруем элементы на основе обновлённого состояния
      const s = currentShop.items
        ? currentShop.items.filter((item: any) =>
          Object.entries(updatedSelection).every(([key, value]) => item[key] === value)
        )
        : currentShop.sliceItems.filter((item: any) =>
          Object.entries(updatedSelection).every(([key, value]) => item[key] === value)
        );

        setSort(s);
      return updatedSelection;
    });
    if (Object.keys(selectedKeys).length === 0) {
      clearSort()
    }
  };

  const getAllValuesForKey = (key: string) => {
    return _.uniq(data.map((item: any) => item[key]).filter(Boolean));
  };

  const isOptionDisabled = (key: string, value: string) => {
    const filteredData = data.filter((item: any) =>
      Object.entries(selectedKeys).every(([k, v]) => item[k] === v)
    );
    return !filteredData.some((item: any) => item[key] === value);
  };

  const renderSelects = () => {
    return allKeys.map((key:any) => {
      const allValues = getAllValuesForKey(key);
      const selectedValue = selectedKeys[key] || '';

      return (
        <div key={key} className="mb-2 flex flex-col my-2">
          <label>{key}</label>
          <select
            className="max-w-[360px]"
            onChange={(e) => handleKeyChange(key, e.target.value)}
            value={selectedValue}>
            <option value=''>Выберите {key}</option>
            {allValues.map((value) => (
              <option
                //@ts-ignore
                key={value}
                //@ts-ignore
                value={value}
                disabled={isOptionDisabled(key, (value as any)) && !selectedValue}>
                {value as any}
              </option>
            ))}
          </select>
        </div>
      );
    });
  };

  return (
      <div className="bg-slate-100 p-5 rounded-xl border-gray-400 max-[834px]:w-full">
        <h2>Выберите значения для фильтрации:</h2>
        {renderSelects()}
      </div>
  );
};

export default Sort