import React, { useEffect } from "react"
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"
import ButtonUI from './uiverse/button-ui';
import Button from './uiverse/button';
import { useMainStore } from "../store/main";
import { useLocalStore } from "../store/local";
import { useAdminStore } from "../store/admin";
import { clearStore } from "../utils";
import { usePageStore } from "../store/page";
import Menu from "./menu";
import MenuSVG from "./svg/burger";

function Navigation() {
    const navigate = useNavigate()
    const location = useLocation()
    const {modal, setModal} = usePageStore<any>((state) => state)
    const { setShop, user } = useMainStore<any>(state => state);
    const { login, shop,
        setCurrentShop,
        setBasketBox,
        setPayBox,
        setManager,
        setSubShops,
        setShops,
        setOrders,
        setUser,
        setLoader,
        setLogin,
    } = useMainStore<any>(state => state);
    //@ts-ignore
    const { setAccessToken } = useLocalStore(state => state);
    const { admin } = useAdminStore<any>(state => state);

   

    return (<>
        {(login && location.pathname !== "/admin") ? <div className='w-full bg-slate-200 h-24 flex justify-between font-jura'>
            <div className="flex items-center gap-10 max-[465px]:gap-5">
                <Link className='text-2xl p-5 font-bold text-slate-600 mr-16 max-[834px]:hidden flex items-center gap-4' to="#"><MenuSVG /> MEGAPOLIS </Link>
               {modal && <Menu />}
            </div>
        </div> : (location.pathname == "/admin") ? <></> : <div className='w-full bg-slate-200 h-24 flex justify-between font-jura'>
            <Link className='max-[834px]:hidden text-2xl p-5 font-bold text-slate-600' to="#">MEGAPOLIS</Link>
            <div className='flex  px-5'>
                <Button text="Вход" path="/login" />
                <Button text="Регистрация" path="/registration" />
            </div>
        </div>}
    </>)
}

export default Navigation