import React from 'react'

type TypeSingleBox = {
    title: string;
    desc: string
}

function SingleBox(props: TypeSingleBox) {
    return <div
        className="cursor-pointer my-5 relative rounded-lg -skew-x-6 -translate-y-2 -translate-y-6 hover:-translate-y-1 hover:-translate-x-0 hover:skew-x-0 duration-500 w-72 h-32 p-2 card-compact transition-all duration-200 [box-shadow:8px_8px_#d1d5db] hover:[box-shadow:4px_4px_#d1d5db]">
        <figure className="w-full h-full">
            <div
                className="text-neutral-50 min-h-full rounded-lg border border-opacity-5"></div>
        </figure>
        <div className="absolute text-gray-600 bottom-4 left-0 px-4">
            <span className="font-bold">{props?.title}</span>
            <p className="text-sm opacity-80 line-clamp-2">
               {props?.desc}
            </p>
        </div>
    </div>

}

export default SingleBox