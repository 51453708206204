import { useEffect } from "react"
import { useMainStore } from "../../store/main"
import NewCardHandler from "./new-card-handler"
function ViewItems({ open, uploadFile }: { open: any[], uploadFile: any }) {
    const { currentShop, setCurrentShop, sort, isSort, setSort } = useMainStore<any>(state => state)

    function removeItem(id: number) {
        if (currentShop?.items) {
            const idx = currentShop?.items.map((el: any) => el.id).indexOf(id)
            const result = [...currentShop.items.slice(0, idx), ...currentShop.items.slice(idx + 1)]
            setCurrentShop({ ...currentShop, items: result })

        } else {
            const idx = currentShop?.sliceItems.map((el: any) => el.id).indexOf(id)
            const result = [...currentShop.sliceItems.slice(0, idx), ...currentShop.sliceItems.slice(idx + 1)]
            setCurrentShop({ ...currentShop, sliceItems: result })
        }
    }

    return <div className="flex justify-around gap-5 flex-wrap">
        {isSort ? <>
            {sort.map((item: any, id: number) => <div key={item?.id} className="max-w-[360px] w-full border border-gray-600 rounded-lg p-5">
                {open[0].indexOf((item.id as never)) !== -1
                    ? <div className="flex gap-5 w-full">
                        <label className="cursor-pointer" onChange={(e) => {
                            const idx = currentShop?.items.map((el: any) => el.id).indexOf(item.id)
                            uploadFile(e, idx, "result_image")
                        }} htmlFor={`${item.id}desc`}>
                            {item.result_image ? <img width={150} height={125} src={item.result_image} />
                                : <svg width={128} height={128} className="border-gray-400" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V11" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4 16L8.29289 11.7071C8.68342 11.3166 9.31658 11.3166 9.70711 11.7071L13 15M13 15L15.7929 12.2071C16.1834 11.8166 16.8166 11.8166 17.2071 12.2071L20 15M13 15L15.25 17.25" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18 8V3M18 3L16 5M18 3L20 5" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            }
                            <input id={`${item.id}desc`} type="file" className="hidden" />
                        </label>
                        <div className="flex flex-col">
                            <textarea onChange={((e) => {
                                const idx = sort.map((el: any) => el.id).indexOf(item.id)
                                setSort([...sort.slice(0, idx), { ...sort[idx], result: e.target.value }, ...sort.slice(idx + 1)])
                            })} placeholder="desc" className="w-[170px] bg-slate-100 border border-slate-300 rounded-sm px-2 h-32 resize-none overflow-y-scroll outline-none rounded-xl">{item.result}</textarea>
                        </div>
                    </div>
                    : <div className="flex gap-5 w-full">
                        <label className="cursor-pointer" onChange={(e) => {
                            const idx = sort.map((el: any) => el.id).indexOf(item.id)
                            uploadFile(e, idx, "image")
                        }} htmlFor={`${item.id}image`}>
                            {item.image ? <img className="max-w-[150px] w-full max-h-[125px] h-full" width={150} height={125} src={item.image} />
                                : <svg width={128} height={128} className="border-gray-400" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V11" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4 16L8.29289 11.7071C8.68342 11.3166 9.31658 11.3166 9.70711 11.7071L13 15M13 15L15.7929 12.2071C16.1834 11.8166 16.8166 11.8166 17.2071 12.2071L20 15M13 15L15.25 17.25" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18 8V3M18 3L16 5M18 3L20 5" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>}
                            <input id={`${item.id}image`} type="file" className="hidden" />
                        </label>
                        <div className="flex flex-col h-32 overflow-y-scroll">
                            {currentShop.keys.map((el: any) => {
                                return <input className="outline-none bg-slate-100 border border-slate-300 rounded-sm px-2 my-0.5" placeholder={el} type="text" value={item[el]} onChange={(e) => {
                                    const index = sort.indexOf(item)
                                    if (index !== -1) {
                                        setSort([...sort.slice(0, index), { ...sort[index], [el]: e.target.value }, ...sort.slice(index + 1)])
                                    }
                                }} />
                            })}
                        </div>
                    </div>}
                <div className="flex items-center">
                    <button className="px-5" onClick={() => removeItem(item.id)}>
                        <svg className="mt-5" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#dc2626"><path d="m376-300 104-104 104 104 56-56-104-104 104-104-56-56-104 104-104-104-56 56 104 104-104 104 56 56Zm-96 180q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520Zm-400 0v520-520Z" /></svg>
                    </button>
                    <button
                        onClick={() => {
                            const idx: number = open[0].indexOf(item.id)
                            if (idx !== -1) {
                                open[1]([...open[0].slice(0, idx), ...open[0].slice(idx + 1)])
                            } else {
                                open[1]([...open[0], item.id])
                            }
                        }}
                        className="mt-5 w-full bg-gray-800 border border-slate-300 rounded-lg py-1 bg-slate-100">{(open[0].indexOf((item.id as never)) !== -1) ? "Продукт" : "Описание"} /SORT/</button>
                </div>
            </div>)}
        </> : currentShop?.items ? currentShop?.items.map((item: any) => <div key={item?.id} className="max-w-[360px] w-full border border-gray-600 rounded-lg p-5">
            {open[0].indexOf((item.id as never)) !== -1
                ? <div className="flex gap-5 w-full">
                    <label className="cursor-pointer" onChange={(e) => {
                        const idx = currentShop?.items.map((el: any) => el.id).indexOf(item.id)
                        uploadFile(e, idx, "result_image")
                    }} htmlFor={`${item.id}desc`}>
                        {item.result_image ? <img width={150} height={125} src={item.result_image} />
                            : <svg width={128} height={128} className="border-gray-400" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V11" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4 16L8.29289 11.7071C8.68342 11.3166 9.31658 11.3166 9.70711 11.7071L13 15M13 15L15.7929 12.2071C16.1834 11.8166 16.8166 11.8166 17.2071 12.2071L20 15M13 15L15.25 17.25" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M18 8V3M18 3L16 5M18 3L20 5" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        }
                        <input id={`${item.id}desc`} type="file" className="hidden" />
                    </label>
                    <div className="flex flex-col">
                        <textarea onChange={((e) => {
                            const idx = currentShop?.items.map((el: any) => el.id).indexOf(item.id)
                            setCurrentShop({ ...currentShop, items: [...currentShop.items.slice(0, idx), { ...currentShop.items[idx], result: e.target.value }, ...currentShop.items.slice(idx + 1)] })
                        })} placeholder="desc" className="w-[170px] bg-slate-100 border border-slate-300 rounded-sm px-2 h-32 resize-none overflow-y-scroll outline-none rounded-xl">{item.result}</textarea>
                    </div>
                </div>
                : <div className="flex gap-5 w-full">
                    <label className="cursor-pointer" onChange={(e) => {
                        const idx = currentShop?.items.map((el: any) => el.id).indexOf(item.id)
                        uploadFile(e, idx, "image")
                    }} htmlFor={`${item.id}image`}>
                        {item.image ? <img className="max-w-[150px] w-full max-h-[125px] h-full" width={150} height={125} src={item.image} />
                            : <svg width={128} height={128} className="border-gray-400" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V11" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4 16L8.29289 11.7071C8.68342 11.3166 9.31658 11.3166 9.70711 11.7071L13 15M13 15L15.7929 12.2071C16.1834 11.8166 16.8166 11.8166 17.2071 12.2071L20 15M13 15L15.25 17.25" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M18 8V3M18 3L16 5M18 3L20 5" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>}
                        <input id={`${item.id}image`} type="file" className="hidden" />
                    </label>
                    <div className="flex flex-col h-32 overflow-y-scroll">
                        {currentShop.keys.map((el: any) => {
                            return <input className="outline-none bg-slate-100 border border-slate-300 rounded-sm px-2 my-0.5" placeholder={el} type="text" value={item[el]} onChange={(e) => {
                                const index = currentShop.items.indexOf(item)
                                if (index !== -1) {
                                    setCurrentShop({
                                        ...currentShop, items: [
                                            ...currentShop.items.slice(0, index),
                                            { ...currentShop.items[index], [el]: e.target.value },
                                            ...currentShop.items.slice(index + 1)
                                        ]
                                    })
                                }
                            }} />
                        })}
                    </div>
                </div>}
            <div className="flex items-center">
                <button className="px-5" onClick={() => removeItem(item.id)}>
                    <svg className="mt-5" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#dc2626"><path d="m376-300 104-104 104 104 56-56-104-104 104-104-56-56-104 104-104-104-56 56 104 104-104 104 56 56Zm-96 180q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520Zm-400 0v520-520Z" /></svg>
                </button>
                <button
                    onClick={() => {
                        const idx: number = open[0].indexOf(item.id)
                        if (idx !== -1) {
                            open[1]([...open[0].slice(0, idx), ...open[0].slice(idx + 1)])
                        } else {
                            open[1]([...open[0], item.id])
                        }
                    }}
                    className="mt-5 w-full bg-gray-800 border border-slate-300 rounded-lg py-1 bg-slate-100">{(open[0].indexOf((item.id as never)) !== -1) ? "Продукт" : "Описание"} /ITEMS/</button>
            </div>
        </div>
        ) : <>
            {currentShop?.sliceItems && currentShop?.sliceItems.map((item: any, id: number) => <div key={item.id} className="max-w-[360px] w-full border border-gray-600 rounded-lg p-5">
                {open[0].indexOf((item.id as never)) !== -1
                    ? <div className="flex gap-5 w-full">
                        <label className="cursor-pointer" onChange={(e) => {
                            const idx = currentShop?.sliceItems.map((el: any) => el.id).indexOf(item.id)
                            uploadFile(e, idx, "result_image")
                        }} htmlFor={`${item.id}desc`}>
                            {item.result_image ? <img width={150} height={125} src={item.result_image} />
                                : <svg width={128} height={128} className="border-gray-400" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V11" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4 16L8.29289 11.7071C8.68342 11.3166 9.31658 11.3166 9.70711 11.7071L13 15M13 15L15.7929 12.2071C16.1834 11.8166 16.8166 11.8166 17.2071 12.2071L20 15M13 15L15.25 17.25" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18 8V3M18 3L16 5M18 3L20 5" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            }
                            <input id={`${item.id}desc`} type="file" className="hidden" />
                        </label>
                        <div className="flex flex-col">
                            <textarea onChange={((e) => {
                                const idx = currentShop?.sliceItems.map((el: any) => el.id).indexOf(item.id)
                                setCurrentShop({ ...currentShop, sliceItems: [...currentShop.sliceItems.slice(0, idx), { ...currentShop.sliceItems[idx], result: e.target.value }, ...currentShop.sliceItems.slice(idx + 1)] })
                            })} placeholder="desc" className="w-[170px] bg-slate-100 border border-slate-300 rounded-sm px-2 h-32 resize-none overflow-y-scroll outline-none rounded-xl">{item.result}</textarea>
                        </div>
                    </div>
                    : <div className="flex gap-5 w-full">
                        <label className="cursor-pointer" onChange={(e) => {
                            const idx = currentShop?.sliceItems.map((el: any) => el.id).indexOf(item.id)
                            uploadFile(e, idx, "image")
                        }} htmlFor={`${item.id}image`}>
                            {item["image"] ? <img className="max-w-[150px] w-full max-h-[125px] h-full" width={150} height={125} src={item["image"]} />
                                : <svg width={128} height={128} className="border-gray-400" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V11" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4 16L8.29289 11.7071C8.68342 11.3166 9.31658 11.3166 9.70711 11.7071L13 15M13 15L15.7929 12.2071C16.1834 11.8166 16.8166 11.8166 17.2071 12.2071L20 15M13 15L15.25 17.25" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18 8V3M18 3L16 5M18 3L20 5" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>}
                            <input id={`${item.id}image`} type="file" className="hidden" />
                        </label>
                        <div className="flex flex-col h-32 overflow-y-scroll">
                            {currentShop.keys.map((el: any) => {
                                return <input className="outline-none bg-slate-100 border border-slate-300 rounded-sm px-2 my-0.5" placeholder={el} type="text" value={item[el]} onChange={(e) => {
                                    const index = currentShop.sliceItems.indexOf(item)
                                    if (index !== -1) {
                                        setCurrentShop({
                                            ...currentShop, sliceItems: [
                                                ...currentShop.sliceItems.slice(0, index),
                                                { ...currentShop.sliceItems[index], [el]: e.target.value },
                                                ...currentShop.sliceItems.slice(index + 1)
                                            ]
                                        })
                                    }
                                }} />
                            })}
                        </div>
                    </div>}
                <div className="flex">
                    <button className="px-5" onClick={() => removeItem(item.id)}>
                        <svg className="mt-5" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#dc2626"><path d="m376-300 104-104 104 104 56-56-104-104 104-104-56-56-104 104-104-104-56 56 104 104-104 104 56 56Zm-96 180q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520Zm-400 0v520-520Z" /></svg>
                    </button>
                    <button
                        onClick={() => {
                            const idx: number = open[0].indexOf(item.id)
                            if (idx !== -1) {
                                open[1]([...open[0].slice(0, idx), ...open[0].slice(idx + 1)])
                            } else {
                                open[1]([...open[0], item.id])
                            }
                        }}
                        className="mt-5 w-full bg-gray-800 border border-slate-300 rounded-lg py-1 bg-slate-100">{(open[0].indexOf((item.id as never)) !== -1) ? "Продукт" : "Описание"} /SLICE ITEMS/</button>
                </div>
            </div>
            )}
        </>}
        <NewCardHandler uploadFile={uploadFile} />
    </div>
}
export default ViewItems