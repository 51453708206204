import React, { useState, useEffect } from "react";
import { useMainStore } from "../../store/main";
import { UserStoreAdd } from "../user-store-add";
import { useLocation } from "react-router-dom";
import AdminDesctiption from "./admin-description";
import AdminHeader from "./admin-header"
import { useLocalStore } from "../../store/local";
import BoxManager from "./box-manager";
import Sort from "../sort";
import TelegramBotSettings from "./telegram-bot-settings";

function AdminMain({ uploadFile }: { uploadFile: any }) {

    const location = useLocation()
    const { access_token } = useLocalStore<any>(state => state)
    const { currentShop, setCurrentShop, user } = useMainStore<any>(state => state)
    const [itemManager, setItemManager] = useState(false)
    const [archiveError, setArchiveError] = useState<boolean | null>(null)

    async function create_data() {
        const itemKeys: string[] = []
        await currentShop.items.map((el: any) => {
            const k = Object.keys(el)
            k.map((el: any) => {
                if (itemKeys.indexOf(el) == -1) {
                    itemKeys.push(el)
                }
            })
        })

        let messages:any = {}
        Object.keys(currentShop.messages).map((el:any) => {
            if (itemKeys.indexOf(el) == -1) {
                return 
            }
            messages[el] = currentShop.messages[el]
        })
        const keys = currentShop.keys.filter((el:any) => el !== "Цена" || el !== "Название");
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/data/`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${access_token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({
                index: Number(location.pathname.split("/").at(-1)) - 1,
                telegram_bot_token: currentShop.telegram_bot_token,
                name: currentShop.name,
                description: currentShop.description,
                items: currentShop.items,
                messages: messages,
                logo: currentShop.logo,
                keys: keys
            })
        }).then(res => {
            return res.json()
        }).then((d) => {
            console.log("T- Promise /api/data/", d)
        })
    };

    async function create_telegram_bot() {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/bot-create`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${access_token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({ telegram_bot_token: currentShop.telegram_bot_token })
        }).then(res => res.json()).then((d) => {
            console.log("T- Promise /api/bot-create", d)
        })
    }

    async function setArchive() {
        const data = currentShop.sliceItems.map((elem: any,) => {
            return { ...elem, user_from: user }
        }
        ) || []
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/set-archive`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${access_token}`,
            },
            body: JSON.stringify({
                bot_name: currentShop.bot_name,
                data: data
            })
        }).then(res => res.json()).then(d => {
            console.log("T- Promise api/set-archive", d)
            setArchiveError(false)
            setTimeout(() => {
                setArchiveError(null)
            }, 2000)
        }).catch(err => {
            setArchiveError(true)
            setTimeout(() => {
                setArchiveError(null)
            }, 2000)
        })
    }

    useEffect(() => {
        console.log(currentShop.sliceItems, "CURRENT TEST")
    }, [])

    return <>
        <div className="flex max-[834px]:flex-col">
            <AdminDesctiption uploadFile={uploadFile} />
            {currentShop.items && <AdminHeader />}
        </div>
        {itemManager && <BoxManager onClick={() => setItemManager(false)} />}
        <div className="flex max-[834px]:flex-col-reverse gap-5 items-end">
            <div className="w-full flex flex-col px-5">
                {currentShop.items && <button
                    onClick={() => {
                        setItemManager(true)
                    }}
                    className={`w-full bg-slate-100 hover:bg-gray-400 mt-2 text-sm transition duration-700 rounded-lg py-2 max-[834px]:mr-0 hover:text-gray-100`}>ЗАГРУЗКИ</button>
                }
                {currentShop?.items ?
                    <button
                        onClick={() => {
                            create_data();
                            setTimeout(() => {
                                create_telegram_bot()
                            }, 1000)
                        }}
                        className={`w-full bg-slate-100 hover:bg-gray-400 mt-2 text-sm transition duration-700 rounded-lg py-2 max-[834px]:mr-0 hover:text-gray-100`}>ОБНОВИТЬ</button>
                    : <button
                        onClick={() => setArchive()}
                        className={`${(archiveError !== null) && (archiveError ? "text-red-600" : "text-green-600")} w-[calc(50%_-_20px)] bg-slate-100 hover:bg-gray-400 transition duration-700 rounded-lg py-2 mt-5 max-[834px]:mr-0 hover:text-gray-100`}>ДОБАВИТЬ</button>}
            </div>
            {currentShop?.items && <UserStoreAdd store={currentShop} />}
        </div>
        <div className="flex justify-between px-5 max-[834px]:flex-col-reverse  gap-5 mt-10">
            <div className="max-[834px]:w-full w-1/2 min-[834px]:pr-5">
                <Sort />
            </div>
            <div className="max-[834px]:w-full w-1/2 min-[834px]:pl-5">
                <TelegramBotSettings />
            </div>
        </div>
    </>
}

export default AdminMain