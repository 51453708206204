import { useState, useMemo } from "react"
import { useLocalStore } from "../../store/local"
import InputUI from "../uiverse/input"
import Loader from "../uiverse/loader";
import Upload from "../upload"
import { TypeShop } from "../../types";
import { debounce } from "../../utils";
import TextareaUI from "../uiverse/textarea";
import { useMainStore } from "../../store/main";

type TypeItem = {
    name: string;
    city: string;
    price: string;
    image: string;
    street: string;
    result: string;
    result_image: string;
}

function ModalAddShop({ onClick, lastIndex, c }: { onClick: any, lastIndex: number, c: any }) {
    const { setCounter } = useMainStore<any>(state => state)
    //@ts-ignore
    const { access_token } = useLocalStore(state => state);
    const [validationTelegramToken, setValidationTelegramToken] = useState<boolean>(false)
    const [state, setState] = useState<TypeShop>({
        telegram_bot_token: "",
        name: "",
        description: "",
        logo: "",
        subdomain: "",
        items: [],
    });
    const [itemValue, setItemValue] = useState<TypeItem>({
        name: "",
        city: "",
        street: "",
        price: "",
        image: "",
        result: "",
        result_image: "",
    });
    const [loader, setLoader] = useState(false);
    const [viewItems, setViewItems] = useState(false)

    async function isValidToken(token: string) {
        try {
            const response = await fetch(`https://api.telegram.org/bot${token}/getMe`);
            // Проверка успешного ответа от Telegram API
            if (response.status > 306) {
                setValidationTelegramToken(false)
            } else {
                setValidationTelegramToken(true)
            }

        } catch (error) {
            setValidationTelegramToken(false)
            return false;
        }
    }

    async function create_data() {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/data/`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${access_token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({
                index: lastIndex,
                logo: state.logo,
                name: state.name,
                description: state.description,
                items: state.items,
                keys: [],
                messages: {},
                telegram_bot_token: state.telegram_bot_token,
            })
        }).then(res => {
            if (res.status < 306) {
                c[1](c[0] + 1)
                document.getElementById("change_modal")?.click()
            }
            return res.json()
        }).then(d => console.log("T- Promnise create-data /api/data"))
    };

    async function uploadFile(e: any, path = 'logo') {
        const formData = new FormData()
        if (e.target.files) {
            formData.append("file", e.target.files[0])
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/file`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${access_token}`,
                "Access-Control-Allow-Origin": "*"
            },
            body: formData
        }).then(res => res.json()).then(d => {
            console.log("T- Promise /api/file")
            if (d) {
                return path == "logo" ? setState({ ...state, logo: `${process.env.REACT_APP_API_URL}/api/${d.path}` }) : path == "result" ? setItemValue({ ...itemValue, result_image: `${process.env.REACT_APP_API_URL}/api/${d.path}` }) : setItemValue({ ...itemValue, image: `${process.env.REACT_APP_API_URL}/api/${d.path}` })
            }
        })
    }

    async function create_telegram_bot() {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/bot-create`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${access_token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({ telegram_bot_token: state.telegram_bot_token })
        }).then(res => res.json()).then(d => console.log("T- Promise /api/bot-create"))
    }


    const memoizedDebounce = useMemo(
        () =>
            debounce((value) => {
                isValidToken(value)
            }, 500),
        [500])

    return <div className="max-w-[860px] h-[386px] max-[625px]:h-[542px] w-full bg-gray-50 rounded-2xl !mx-10">
        {loader
            ? <div className="flex h-full justify-center items-center h-[386px]  0"><Loader /></div>
            : <>
                <div className={` px-8 w-full flex justify-between mt-4`}>
                    <span
                        className="flex items-end justify-end  block">
                    </span>
                    <div id="change_modal" onClick={onClick} >
                        <svg
                            className="h-8 w-8 cursor-pointer translate-x-4" xmlns="http://www.w3.org/2000/svg" fill="#475569" viewBox="0 0 24 24" stroke="#475569" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <div className="w-full flex flex-col justify-center">
                    {viewItems ? <template className="mt-5 flex justify-center p-10 flex-wrap gap-5">
                    </template> :
                        <div className="flex max-[625px]:flex-col justify-around">
                            <div className="flex gap-5 px-5 flex-col w-96 max-[625px]:w-full">
                                <span>
                                    <span className="text-lg">Название</span>
                                    <InputUI value={state.name} onChange={(e: any) => setState({ ...state, name: e.target.value })} />
                                </span>
                                <span>
                                    <span className="text-lg text-nowrap">Телеграм бот токен <a className="text-blue-500" href="https://t.me/BotFather" target="_blank">https://t.me/BotFather</a></span>
                                    <InputUI
                                        onChange={(e: any) => {
                                            setState({ ...state, telegram_bot_token: e.target.value })
                                            memoizedDebounce(e.target.value)
                                        }}
                                        isValid={validationTelegramToken} value={state.telegram_bot_token} />
                                </span>
                                <span>
                                    <span className="text-lg">Описание</span>
                                    <TextareaUI value={state.description} onChange={(e: any) => setState({ ...state, description: e.target.value })} />
                                </span>
                            </div>
                            <div className="w-96 px-5 max-[625px]:w-full">
                                <span>
                                    <span className="text-lg">Логотип</span>
                                    <Upload id="first_drop_zone" path={state.logo} onChange={(e: any) => uploadFile(e, "logo")} />
                                </span>
                            </div>
                        </div>
                    }
                    <div className="flex justify-center gap-5 mt-5">
                        <button
                            onClick={() => {
                                create_data()
                                setLoader(true)
                                setTimeout(() => {
                                    setLoader(false)
                                    create_telegram_bot()
                                    setCounter()
                                    onClick()
                                }, 1500)
                            }}
                            disabled={!validationTelegramToken}
                            type="button" className="w-full select-none rounded-b-xl bg-gray-300 hover:bg-gray-400 py-4 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-500/20 transition duration-500 hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none  disabled:shadow-none">
                            Создать
                        </button>
                    </div>
                </div>
            </>}
    </div>
}
export default ModalAddShop