import { useEffect, useState } from "react"
import { useLocalStore } from "../store/local"
import { useLocation } from "react-router-dom"
import { useMainStore } from "../store/main"

function StoreBasketPage() {

    const location = useLocation()
    const { basket, setBasket, setBasket_, transactions, addTransaction } = useLocalStore<any>((state) => state)
    const { basketBox, setBasketBox, payBox, setPayBox, state, location_store, setLocationStore } = useMainStore<any>(state => state)
    const [sum, setSum] = useState<number>(0)
    const [basketPay, setBasketPay] = useState<any>(null)

    const [error, setError] = useState<boolean | null>(null)

    function sumOrder() {
        setSum(0)
        let x = basket.reduce((acc: any, obj: any) => {
            return acc += obj.item.price ? +obj.item.price : +obj.item["Цена"]
        }, 0)
        setSum(x)
    }

    useEffect(() => {
        sumOrder()
    }, [basket])

    function del(id: number) {
        const newArray = basket.reduce((acc: any, obj: any) => {
            if ((obj.name == location.pathname.split("/")[1]) && (obj.id !== id)) {
                acc.push(obj);
            }
            return acc;
        }, []);
        setBasket_(newArray)
    }


    async function pay(data: any, type: string) {
        const b = basket.filter((el: any) => el.name.replace("/", "") === location.pathname.split("/").at(-2)).map((el: any) => el.item)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-pay`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow": "*"
            },
            body: JSON.stringify({
                user_id: null,
                type_fiat: type,
                amount_rub: sum,
                address_btc: state.wallet.address,
                items: b,
                sub: state.sub,
                bot_name: location.pathname.split("/").at(-2)
            })
        }).then((res: Response) => res.json()).then((d: any) => {
            if (!d.error) {
                setBasketPay({ ...d, basket: basket })
                addTransaction({ store: location.pathname.split("/")[1], data: b, pay: d })
            } else {
                setError(true)
            }
        }).catch(err => console.error(err))
    }

    useEffect(() => {
        setLocationStore(location.pathname.split("/")[1].replace("/", ""))
    }, [])


    return <div>
         {(basket.length == 0) ? <div className="text-center my-5">В корзине нет товаров.</div>
            : <div className="bg-slate-200 rounded-xl min-w-[380px] max-w-[380px] mt-5">
                <div className="gap-2 p-5 flex justify-between bg-slate-400 text-white rounded-lg"> 
                    <div className="flex flex-col gap-2">
                        <span className="py-1">Итого: {sum}</span>
                        <span className="py-1">Количество: {basket.length}.шт</span>
                    </div>
                    <div className="gap-2 flex flex-col">
                        <button
                            onClick={() => pay(basket.items, "SBP")}
                            className="w-[140px] border border-slate-100 rounded-sm bg-slate-400 hover:bg-slate-600 transition duration-700 text-slate-100 py-1 mx-auto">Оплата СБП</button>
                        <button
                            onClick={() => pay(basket.items, "CARD")}
                            className="w-[140px] border border-slate-100 rounded-sm bg-slate-400 hover:bg-slate-600 transition duration-700 text-slate-100 py-1 mx-auto">Оплата карой</button>
                    </div>
                </div>
            </div>}
        {basketPay && <div className="bg-slate-200 border border-slate-400 rounded-xl min-w-[380px] max-w-[380px] mt-5">
            <div className="gap-2 p-5 flex flex-col">
                <div className="flex flex-col">
                    <span>Заявка: {basketPay.order}</span>
                    <span>Реквизиты: {basketPay.paymentData}</span>
                    <span>Сумма: {basketPay.amountExc}₽</span>
                    <div className="flex justify-around flex-wrap">
                        {basketPay.basket
                            .map((data: any) => <div key={data.id} className="bg-slate-200 text-[8px] border border-slate-400 rounded-xl min-w-[160px] max-w-[160px] mt-5">
                                <div className="flex gap-2.5 p-2.5">
                                    <img className="w-12 h-12 rounded-lg" src={data.item.image} />
                                    <div className="flex flex-col">
                                        {Object.keys(data.item).map((x => {
                                            if ((x == "id") || (x == "image") || (x == "handler")) return
                                            return <span>{x == "city" ? "Город"
                                                : x == "name" ? "Название"
                                                    : x == "street" ? "Улица"
                                                        : x === "price" ? "Цена"
                                                            : x}: {data.item[x]}</span>
                                        }))}
                                    </div>
                                </div>
                            </div>)}
                    </div>
                </div>
            </div>
        </div>}
       
        {(basket.length > 0) && basket
            .filter((el: any) => el.name.replace("/", "") === location.pathname.split("/").at(-2))
            .map((data: any) => <div key={data.id} className="bg-slate-200 border border-slate-400 rounded-lg min-w-[380px] max-w-[380px] mt-5">
                <div className="flex gap-5 p-5">
                    <img className="w-36 h-36 rounded-lg" src={data.item.image} />
                    <div className="flex flex-col">
                        {Object.keys(data.item).map((x => {
                            if ((x == "id") || (x == "image") || (x == "handler")) return
                            return <span>{x == "city" ? "Город"
                                : x == "name" ? "Название"
                                    : x == "street" ? "Улица"
                                        : x === "price" ? "Цена"
                                            : x}: {data.item[x]}</span>
                        }))}
                        <button
                            onClick={() => del(data.item.id)}
                            className="mt-4 px-2 py-1 rounded-sm bg-slate-400 hover:bg-slate-600 text-white">Удалить</button>
                    </div>
                </div>
            </div>)}
    </div>
}

export default StoreBasketPage