
import { useMainStore } from "../../store/main";

import Landing from "./landing";
import  Container  from "../../components/container"

function HomePage(){
    //@ts-ignore
    const {login} = useMainStore(state => state)
    
    return <div className="h-full flex flex-col bg-slate-200 font-jura px-5">
        <Container>
            {login ? <></> : <Landing />}
        </Container>
    </div>
}
export default HomePage