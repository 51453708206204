import { useEffect, useState } from "react"
import { useLocalStore } from "../../store/local"

function Card({ data, onClick }: { data: any, onClick: any }) {

    const [state, setState] = useState(false)
    const { access_token } = useLocalStore<any>((state) => state)

    return <div className="w-[360px]  p-5 border border-gray-400 rounded-xl my-4">
        {!state ? <div className="flex gap-5 ">
            <div className="w-[160px] flex flex-col">
                <img className="w-[160px] h-[120px]" src={data.image} />
                <span className="mt-3">Курьер: {data.user_from}</span>
            </div>
            <div className=" flex flex-col  cursor-pointer">
                {Object.keys(data).map((key, id) => {
                    if ((key == "image") || (key == "result_image") || (key == "result") || (key == "id") || (key == "user_from") || (key == "cost")) return
                    return <span className="break-all">{key}: {data[key]}</span>
                })}
            </div>
        </div> : <div className="flex gap-5">
            <div className="w-[160px] h-[120px]">
                <img className="w-[160px] h-[120px]" src={data.result_image} />
            </div>
            <div className=" flex flex-col">
                <span>{data.result}</span>
            </div>
        </div>}
        <div className="flex">
            <button className="w-1/2 py-1 mt-5 w-full bg-gray-800 border  border-slate-300 rounded-lg rounded-r-none bg-slate-100" onClick={() => setState(!state)}>{!state ? "Описание" : "Продукт"}</button>
            <button className="w-1/2 py-1 mt-5 w-full bg-gray-800 border  border-slate-300 rounded-lg rounded-l-none bg-slate-100" onClick={onClick}>Добавить</button>
        </div>
    </div>
}

export default Card