import TextBox from "../../components/uiverse/text-box";
import { CreateSVG } from "../../components/svg/ui-create";
import { DataSVG } from "../../components/svg/ui-data";
import { MonitoringSVG } from "../../components/svg/ui-moitoring";
import SingleBox from "../../components/uiverse/single-box";
import { useMainStore } from "../../store/main";

function Landing() {
    const {login} = useMainStore<{login: boolean}>(state => state as any) 
    return <>
        <div id='first_box' className="my-12">
            <h1 className="text-4xl text-center font-bold my-4 text-gray-600 mt-10">МЕГАПОЛИС - АВТОМАТИЗАЦИЯ ВАШЕГО БИЗНЕСА</h1>
            <p className="text-xl text-gray-800 font-normal max-w-[820px] w-full mx-auto text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe magnam odio eveniet, expedita temporibus magni! Nemo, obcaecati temporibus eum odit praesentium, tempora atque magnam, eos ut quisquam velit commodi.</p>
        </div>

        <div className="my-24 flex justify-around gap-10 mx-auto max-w-[720px] w-full flex-wrap">
            <SingleBox title="Удобный интерфейс." desc="Создание магазина занимает несколько минут." />
            <SingleBox title="Всего 2% от сделки." desc="Мы не берем ежемесячную плату за использование." />
        </div>

        <div className="my-44 flex justify-around gap-10 mx-auto max-w-[1280px] flex-wrap">
            <TextBox svg={<CreateSVG size={54} fill="" />} num={"01"} title="Создание бота" desc="Регистрация. Создание и подключение телеграм бота." />
            <TextBox svg={<DataSVG size={54} fill="" />} num={"02"} title="Ввод данных" desc="Загрузка в магазин данных о товаре или услуге." />
            <TextBox svg={<MonitoringSVG size={54} fill="" />} num={"03"} title="Мониторинг" desc="Мониторинг продаж, активности." />
        </div>
    </>
}

export default Landing