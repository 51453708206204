import { useMainStore } from "../../store/main"
import ImageCard from "../image-card"
function AdminDesctiption({uploadFile}: {uploadFile:any}) {
    
    const {currentShop, setCurrentShop} = useMainStore<any>(state => state)

    return <div className="px-5 w-1/2 max-[834px]:w-full">
    <div>
        <ImageCard onChange={(e: any) => uploadFile(e)} path={currentShop && currentShop?.logo} />
        <div className="flex flex-col gap-5 max-w-[600px] min-w-[320px] w-full mt-5 max-[834px]:max-w-full">
            <label className="flex flex-col text-xl">Название
                <input placeholder="Название" className="w-full text-2xl outline-none bg-slate-100 p-2 border border-slate-300 rounded-lg" onChange={(e) => setCurrentShop({ ...currentShop, name: e.target.value })} value={currentShop && currentShop.name} />
            </label>
            <label className="flex flex-col text-xl">Описание
                <textarea placeholder="Описание" value={currentShop && currentShop.description} onChange={(e) => setCurrentShop({ ...currentShop, description: e.target.value })} className="text-xl p-2 border border-slate-300 h-full resize-none outline-none rounded-lg bg-slate-100"></textarea>
            </label>
        </div>
    </div>
</div>
}

export default AdminDesctiption