import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useMainStore } from "../store/main"

function Registration() {
  const navigate = useNavigate()
  //@ts-ignore
  const { loader, setLoader } = useMainStore(state => state)
  const [error, setError] = useState<string | null>(null)
  const [user, setUser] = useState({
    login: "",
    password: "",
    confirm: "",
    secret: ""
  })

  async function handler() {
    if (user.password !== user.confirm) {
      setError("Пароли не совпадают")
      return
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/registration`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify({ login: user.login, password: user.password, secret: user.secret, roles:false, store: "any" })
      }).then(res => {
        if (res.status > 306) {
          setError("Ошибка регистрации")
        }
        return res.json()
      }).then(d => {
        setError(null)
        setLoader(true)
        setTimeout(() => {
          setLoader(false)
          return navigate("/login")
        }, 1500)
        
      })
    } catch (error) {
      setError("Ошибка регистрации")
    }

  }


  return <div className="relative py-3 mx-auto mt-16">
    <div className="relative py-10 bg-white shadow rounded-3xl p-10 mx-auto  w-[380px]">
      <div className="max-w-md mx-auto">
        <div className="mt-5 gap-5">
          <div className="w-full">
            <label
              className="font-semibold text-sm text-gray-600 pb-1 block"
            >Login</label>
            <input
              value={user.login}
              onChange={(e) => setUser({ ...user, login: e.target.value })}
              className="border w-[100%] rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
              type="text"
              id="login" />
          </div>
          <div>
            <label
              className="font-semibold text-sm text-gray-600 pb-1 block">Password</label>
            <input
              onChange={(e) => setUser({ ...user, password: e.target.value })}
              value={user.password}
              className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
              type="password"
              id="password"/>
          </div>
          <div>
            <label
              className="font-semibold text-sm text-gray-600 pb-1 block">Confirm Password</label>
            <input
              onChange={(e) => setUser({ ...user, confirm: e.target.value })}
              value={user.confirm}
              className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
              type="password"
              id="confirm" />
          </div>
          {/* <div>
            <label
              className="font-semibold text-sm text-gray-600 pb-1 block">Secret</label>
            <input
              onChange={(e) => setUser({ ...user, secret: e.target.value })}
              value={user.secret}
              className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
              type="text"
              id="secret" />
          </div> */}
        </div>
        <div className="mt-5">
          <button
            onClick={() => handler()}
            className="py-2 px-4 bg-gray-400 hover:bg-gray-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
            type="submit">Регистрация</button>
        </div>
        {error &&
          <div className="flex text-sm items-center justify-between mt-4 text-red-600">
            <span className="w-1/4 border-b border-red-600"></span>
            {error}
            <span className="w-1/4 border-b border-red-600 "></span>
          </div>
        }
        <div className="flex items-center justify-between mt-4">
          <span className="w-1/4 border-b  border-gray-600"></span>
          <Link
            className="text-xs text-gray-00 uppercase  hover:underline"
            to="/login">Есть аккаунт? Войти</Link>
          <span className="w-1/4 border-b  border-gray-600"></span>
        </div>
      </div>
    </div>
  </div>

}

export default Registration
