import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useLocalStore } from "../store/local"
import { useMainStore } from "../store/main"

function Authorization() {
    const navigate = useNavigate()
    const location = useLocation()
    const {access_token, setAccessToken} = useLocalStore<any>(state => state)
    const {login, setLogin, setLoader, setShop, setUser, user} = useMainStore<any>(state => state)
    const [error, setError] = useState<string | null>(null)
    const [u, setU] = useState({
        login: "",
        password: ""
    })

    async function get_profile(token: string) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Authorization": `Bearer ${token}`
            }
          }).then(res => {
            if (res.status === 401) {
              setAccessToken(null)
              setLogin(false)
              setShop([])
              if (location.pathname == "/admin") return
            }
            if (res.status < 306) setLogin(true)
            return res.json()
          }).then(d => {
            setUser(d.login)
          })
        } catch (error) {
          console.log(error)
        }
      }

    async function handler() {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
                },
                body: JSON.stringify({...u, roles:false, store: "any"})
            }).then(res => {
                if (res.status > 306) {
                    setError("Данные не прошли проверку")
                    setLogin(false)
                    return navigate("/login")
                }
                return res.json()
            }).then(d => {
                if (d.access_token) {
                    setError(null)
                    setLoader(true)
                    setAccessToken(d.access_token)
                    setTimeout(async () => {
                    await get_profile(d.access_token)
                      setLoader(false)
                      setLogin(true)
                      return navigate("/login")
                    }, 1500)
                }
            })
    }

    return <div className="relative py-3">
        <div
            className="relative bg-white  shadow rounded-3xl p-10 w-[380px] mt-16  mx-auto">
            <div className="max-w-md mx-auto">
                <div>
                    <div className="mt-5  gap-5">
                        <div>
                            <label
                                className="font-semibold text-sm text-gray-600 pb-1 block"
                            >Login</label>
                            <input
                                value={u.login}
                                onChange={(e) => setU({ ...u, login: e.target.value })}
                                className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                                type="text"
                                id="username"
                            />
                        </div>
                        <div>
                            <label
                                className="font-semibold text-sm text-gray-600 pb-1 block"
                            >Password</label>
                            <input
                                value={u.password}
                                onChange={(e) => setU({ ...u, password: e.target.value })}
                                className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                                type="password"
                                id="password"
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <button
                        onClick={() => handler()}
                        className="py-2 px-4 bg-gray-400 hover:bg-gray-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                        type="submit"
                    >
                        Вход
                    </button>
                </div>
                {error &&
                    <div className="flex text-sm items-center justify-between mt-4 text-red-600 text-nowrap">
                        <span className="border-b border-red-600 w-2/12"></span>
                            {error}
                        <span className="border-b border-red-600 w-2/12"></span>
                    </div>
                }
                <div className="flex items-center justify-between mt-4">
                    <span className="w-1/6 border-b border-gray-600"></span>
                    <Link
                        className={`"text-gray-500 text-xs  uppercase hover:underline`}
                        to="/registration"
                    >Нет аккаунта? Регистрация</Link>
                    <span className="w-1/6 border-b border-gray-600 "></span>
                </div>
                
            </div>
        </div>
    </div>
}

export default Authorization
