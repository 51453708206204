import Navigation from "./navigation";
import { useMainStore } from "../store/main";
import { useAdminStore } from "../store/admin";
import { useEffect } from "react";
import { useLocalStore } from "../store/local";
import { useLocation } from "react-router-dom";
import { clearStore } from "../utils";
import { usePageStore } from "../store/page";

function Container(props: any) {
    const location = useLocation()
    const {setAccessToken, access_token} = useLocalStore<any>((state) => state)
    const { login,
        setCurrentShop,
        setBasketBox,
        setPayBox,
        setManager,
        setSubShops,
        setOrders,
        setUser,
        setLogin,
        setShop, 
     } = useMainStore<any>(state => state);

    useEffect(() => {
        get_profile()
    }, [])

    async function get_profile() {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Authorization": `Bearer ${access_token}`
            }
          }).then(res => {
            if (res.status === 401) {
              setAccessToken(null)
              setLogin(false)
              setShop([])
              clearStore(
                setAccessToken,
                setCurrentShop,
                setBasketBox,
                setPayBox,
                setManager,
                setSubShops,
                setShop,
                setOrders,
                setUser,
                setLogin,
              )
              if (location.pathname == "/admin") return
            }
            if (res.status < 306) setLogin(true)
            return res.json()
          }).then(d => {
            setUser(d.login)
          })
        } catch (error) {
          console.log(error)
        }
      }

    return <div className="w-full h-full" >
        {(login !== null) && <>
            <Navigation />
            <div className="min-h-[calc(100vh_-_96px)] h-full w-full ">
                {props.children}
            </div>
        </>}
    </div>
}

export default Container