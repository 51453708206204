import { NavLink, Link } from "react-router-dom"
import MenuSVG from "./svg/burger"
import { useMainStore } from "../store/main";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { clearStore } from "../utils";
import { useLocalStore } from "../store/local";
function Menu() {
    const { user, login, shop,
        setCurrentShop,
        setBasketBox,
        setPayBox,
        setManager,
        setSubShops,
        setShops,
        setOrders,
        setUser,
        setLoader,
        setLogin,
    } = useMainStore<any>(state => state);
    const { setAccessToken, setShop } = useLocalStore<any>(state => state)
    const navigate = useNavigate()
    const location = useLocation()
    function exit() {
        setLoader(true)
        setTimeout(() => {
            clearStore(
                setAccessToken,
                setCurrentShop,
                setBasketBox,
                setPayBox,
                setManager,
                setSubShops,
                setShop,
                setOrders,
                setUser,
                setLogin,
            )
            setLoader(false)
            return navigate("/")
        }, 1500)
    }


    return <div className="w-[320px] absolute min-h-screen left-0 flex flex-col gap-5  px-5 py-2.5 bg-slate-300 top-0 z-50 justify-between">
        <div className="flex flex-col gap-5 ">
            <div className="flex items-center gap-10 max-[465px]:gap-5">
                <label className='text-2xl p-5 font-bold text-slate-600 mr-16 max-[834px]:hidden flex gap-4 items-center'><MenuSVG /> MEGAPOLIS </label>
            </div>
            <div className="bg-slate-100 rounded-lg flex flex-col gap-2 p-3">
                <NavLink className=" translate-y-1 max-[462px]:text-[14px] max-[462px]:pb-1 transition duration-1000 border-b border-gray-800 border-opacity-0  aria-[current=page]:border-opacity-100 text-lg text-gray-700" to="/">Главная</NavLink>
                <NavLink className=" translate-y-1 max-[462px]:text-[14px] max-[462px]:pb-1 transition duration-1000 border-b border-gray-800 border-opacity-0  aria-[current=page]:border-opacity-100 text-lg text-gray-700" to="/shops">Магазины</NavLink>
            </div>
            <div className="bg-slate-100 rounded-lg flex flex-col gap-2 p-3 z-50">
                <NavLink className="translate-y-1 max-[462px]:text-[14px] max-[462px]:pb-1 transition duration-1000 border-b border-gray-800 border-opacity-0  aria-[current=page]:border-opacity-100 text-lg text-gray-700" to="/1">Продажи</NavLink>
                <NavLink className=" translate-y-1 max-[462px]:text-[14px] max-[462px]:pb-1 transition duration-1000 border-b border-gray-800 border-opacity-0  aria-[current=page]:border-opacity-100 text-lg text-gray-700" to="/2">Остаток товаров</NavLink>
                <NavLink className=" translate-y-1 max-[462px]:text-[14px] max-[462px]:pb-1 transition duration-1000 border-b border-gray-800 border-opacity-0  aria-[current=page]:border-opacity-100 text-lg text-gray-700" to="/3">Статистика</NavLink>
                <NavLink className=" translate-y-1 max-[462px]:text-[14px] max-[462px]:pb-1 transition duration-1000 border-b border-gray-800 border-opacity-0  aria-[current=page]:border-opacity-100 text-lg text-gray-700" to="/4">Акт сверки</NavLink>
                <NavLink className=" translate-y-1 max-[462px]:text-[14px] max-[462px]:pb-1 transition duration-1000 border-b border-gray-800 border-opacity-0  aria-[current=page]:border-opacity-100 text-lg text-gray-700" to="/5">Клиенты</NavLink>
                <NavLink className=" translate-y-1 max-[462px]:text-[14px] max-[462px]:pb-1 transition duration-1000 border-b border-gray-800 border-opacity-0  aria-[current=page]:border-opacity-100 text-lg text-gray-700" to="/6">Отзывы</NavLink>
                <NavLink className=" translate-y-1 max-[462px]:text-[14px] max-[462px]:pb-1 transition duration-1000 border-b border-gray-800 border-opacity-0  aria-[current=page]:border-opacity-100 text-lg text-gray-700" to="/6">Чат с клиентами</NavLink>
            </div>
            <div className="bg-slate-100 rounded-lg flex flex-col gap-2 p-3">
                <NavLink className=" translate-y-1 max-[462px]:text-[14px] max-[462px]:pb-1 transition duration-1000 border-b border-gray-800 border-opacity-0  aria-[current=page]:border-opacity-100 text-lg text-gray-700" to="/7">Категории</NavLink>
                <NavLink className=" translate-y-1 max-[462px]:text-[14px] max-[462px]:pb-1 transition duration-1000 border-b border-gray-800 border-opacity-0  aria-[current=page]:border-opacity-100 text-lg text-gray-700" to="/8">Товары</NavLink>
            </div>
        </div>
        <div>

            <div className="bg-slate-100 rounded-lg flex flex-col gap-2 p-3">

                <NavLink className=" translate-y-1 max-[462px]:text-[14px] max-[462px]:pb-1 transition duration-1000 border-b border-gray-800 border-opacity-0  aria-[current=page]:border-opacity-100 text-lg text-gray-700" to="/7"></NavLink>
                <NavLink className=" translate-y-1 max-[462px]:text-[14px] max-[462px]:pb-1 transition duration-1000 border-b border-gray-800 border-opacity-0  aria-[current=page]:border-opacity-100 text-lg text-gray-700" to="/8"></NavLink>

                <div className="flex justify-between items-center pt-3 gap-2">
                    <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#64748b"><path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" /></svg>
                        <span className="text-lg text-slate-600">{user}</span>
                    </div>
                    <div onClick={() => exit()} className="underline px-5 cursor-pointer">
                        Выход
                    </div>
                </div>
            </div>
        </div>

    </div>
}

export default Menu