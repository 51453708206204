import { useEffect, useState } from "react"
import { useLocalStore } from "../store/local"
import { useMainStore } from "../store/main"
import { useLocation } from "react-router-dom"

function StoreFindOrders() {
    const location = useLocation()
    const { transactions, removeTransaction, updateTransaction, addTransaction } = useLocalStore<any>(state => state)
    const { location_store, setLocationStore } = useMainStore<any>(state => state)

    const [results, setResult] = useState<any>([])

    useEffect(() => {
        setLocationStore(location.pathname.split("/").at(-2))
    }, [])

    function viewTransactions() {

        if (transactions.length == 0) {
            return <span></span>
        }

        const orders = transactions.filter((el: any) => el.store === location_store)

        return <div className="flex flex-wrap gap-5 justify-center">
            {orders.map((order: any) => <div>
                <div className="px-5 w-[240px] pt-2 pb-4 border border-gray-400 rounded-xl mt-5">
                    {Array.isArray(order.data)
                        ? order.data.map((d: any) =>
                            <>
                                {order.status == "complete"
                                    ? <div className="flex flex-col mt-3">
                                        <div className="flex gap-5">
                                            <img className="max-w-[60px] max-h-[80px] min-w-[60px]" src={d.result_image} />
                                            <div className="flex flex-col">
                                                <span className="text-xs break-all">Результат: {d.result}</span>
                                            </div>
                                        </div>
                                    </div>
                                    : <div className="flex flex-col mt-3">
                                        <div className="flex gap-5">
                                            <img className="max-w-[60px] max-h-[80px] min-w-[60px]" src={d.image} />
                                            <div className="flex flex-col">
                                                {Object.keys(d).map((key: any) => {
                                                    if ((key == "id") || (key === "image") || (key == "handler") || (key == "") || (key == "result_image") || (key == "result")) return
                                                    return <span className="text-xs break-all">{key}: {d[key]}</span>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                }       </>) : <>{order.status == "complete"

                                    ? <div className="flex gap-5 mt-3">
                                        <img className="max-w-[60px] max-h-[80px] min-w-[60px]" src={order.data.result_image} />
                                        <div className="flex flex-col">
                                            return <span className="text-xs break-all">Результат {order.data.result}</span>
                                        </div>
                                    </div> : <div className="flex gap-5 mt-3">
                                        <img className="max-w-[60px] max-h-[80px] min-w-[60px]" src={order.data.image} />
                                        <div className="flex flex-col">
                                            {Object.keys(order.data).map((key: any) => {
                                                if ((key == "id") || (key === "image") || (key == "result_image") || (key == "result")) return
                                                return <span className="text-xs break-all">{key}: {order.data[key]}</span>
                                            })}
                                        </div>
                                    </div>
                                } </>}
                    <div className="flex flex-col mt-5">
                        {Object.keys(order.pay).map((key) => <span className="text-xs">
                            {key}: {order.pay[key]}
                        </span>)}
                        <button
                            onClick={() => checkPaymentItems(order.pay.order)}
                            className="py-1 w-full text-xs rounded-sm bg-slate-400 hover:bg-slate-600 transition duration-700 text-slate-100 py-1 mx-auto mt-3">Проверить</button>


                        <button
                            onClick={() => removePaymentItems(order.pay.order)}
                            className="py-1 w-full text-xs rounded-sm bg-slate-400 hover:bg-slate-600 transition duration-700 text-slate-100 py-1 mx-auto mt-3">Удалить</button>
                    </div>
                </div>
            </div>
            )}
        </div>
    }

    async function checkPaymentItems(order: number) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/check-order`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({
                order: order
            })
        }).then(res => res.json()).then(data => {

            updatePaymentItems({
                store: data.bot_name,
                data: data.item,
                pay: { order: +data.order, amountExc: data.amountExc, paymentData: data.paymentData },
                status: "complete"
            })
        })
    }

    function removePaymentItems(order: number) {
        const idx = transactions.map((order: any) => order.pay.order).indexOf(order)
        removeTransaction(idx)
    }

    function updatePaymentItems(object: any) {
        const idx = transactions.map((order: any) => order.pay.order).indexOf(object.pay.order)
        console.log(idx, "IDX TEST")
        updateTransaction(object, idx)
    }

    return <div className="flex flex-wrap justify-center gap-5">
        {viewTransactions()}
    </div>
}

export default StoreFindOrders