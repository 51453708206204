import { useEffect, useState } from "react"
import { useMainStore } from "../../store/main"
import { useLocalStore } from "../../store/local"
import Container from "../../components/container"
import { useLocation, useNavigate } from "react-router-dom"
import SortItem from "../../components/sort-item"
import AdminMain from "../../components/shop-admin-panel/admin-main"
import * as _ from 'lodash'

function ShopAdminPanelPage() {
    const location = useLocation()
    const navigate = useNavigate()

    const {
        newObject, setNewObject,
        currentShop, setCurrentShop,
        user,
        setManager,
        order
    } = useMainStore<any>(state => state);

    const { access_token } = useLocalStore<any>(state => state);
    const [openItem, setOpenItem] = useState<number[] | []>([]);

    useEffect(() => {
        if (currentShop == null) {
            return navigate("/shops")
        }
        if (!currentShop?.items) {
            getArchive()
        }
        if (currentShop?.items && currentShop?.items !== null) {
            getManagetStoreAPI()
        }
        getTransactions()
    }, [])


    async function getManagetStoreAPI() {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-store-archive`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${access_token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({ bot_name: currentShop.bot_name })
        }).then(res => res.json()).then(d => setManager(d))
    }

    async function uploadFile(e: any, index: number | null = null, path = "logo") {
        const formData = new FormData()
        if (e.target.files) {
            formData.append("file", e.target.files[0])
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/file`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${access_token}`,
                "Access-Control-Allow-Origin": "*"
            },
            body: formData
        }).then(res => res.json()).then(d => {
            if (d) {
                if (currentShop.items) {
                    if (index === null) {
                        setCurrentShop({ ...currentShop, logo: `${process.env.REACT_APP_API_URL}/api/${d.path}` })
                    } else if (path == "image") {
                        setCurrentShop({ ...currentShop, items: [...currentShop.items.slice(0, index), { ...currentShop.items[index], image: `${process.env.REACT_APP_API_URL}/api/${d.path}` }, ...currentShop.items.slice(index + 1)] })
                    } else if (path == "result_image") {
                        setCurrentShop({ ...currentShop, items: [...currentShop.items.slice(0, index), { ...currentShop.items[index], result_image: `${process.env.REACT_APP_API_URL}/api/${d.path}` }, ...currentShop.items.slice(index + 1)] })
                    }
                } else {
                    if (index === null) {
                        setCurrentShop({ ...currentShop, logo: `${process.env.REACT_APP_API_URL}/api/${d.path}` })
                    } else if (path == "image") {
                        setCurrentShop({ ...currentShop, sliceItems: [...currentShop.sliceItems.slice(0, index), { ...currentShop.sliceItems[index], image: `${process.env.REACT_APP_API_URL}/api/${d.path}` }, ...currentShop.sliceItems.slice(index + 1)] })
                    } else if (path == "result_image") {
                        setCurrentShop({ ...currentShop, sliceItems: [...currentShop.sliceItems.slice(0, index), { ...currentShop.sliceItems[index], result_image: `${process.env.REACT_APP_API_URL}/api/${d.path}` }, ...currentShop.sliceItems.slice(index + 1)] })
                    }
                }

                if (path == "new-item-image") {
                    setNewObject({ ...newObject, image: `${process.env.REACT_APP_API_URL}/api/${d.path}` })
                } else if (path == "new-item-image-result") {
                    setNewObject({ ...newObject, result_image: `${process.env.REACT_APP_API_URL}/api/${d.path}` })
                }
            }
        })
    }

    async function getTransactions() {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/search-store-orders`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${access_token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*" 
            },
            body: JSON.stringify({
                bot_name: currentShop.bot_name
            })
        }).then(res => res.json()).then(d => console.log("T- Promise transaction /api/search-store-orders"))
    }

    async function getArchive() {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-archive`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${access_token}`
            },
            body: JSON.stringify({
                bot_name: currentShop?.bot_name,
                login: user
            })
        }).then((res) => res.json()).then(d => {
            console.log("T- Promise /api/get-archive")

            function sortKeys(obj: any, order: any) {
                let sortedObj: any = {};
                order.forEach((key: any) => {
                    if (obj.hasOwnProperty(key)) {
                        sortedObj[key] = obj[key];
                    }
                });
                return sortedObj;
            }
            var findStoreArchive: any
            if (d[0]) {
                let o = [ ...order, ...Object.keys(currentShop.sliceItems), "cost"];
                findStoreArchive = d[0]["archive"].find((el: any) => el["bot_name"] == currentShop.bot_name)
                const sortedData = findStoreArchive?.data.map((item: any) => sortKeys(item, o));
                setCurrentShop({ ...currentShop, sliceItems: sortedData })
            } else {
                setCurrentShop({ ...currentShop, sliceItems: [] })
            }})}

    return <>
        <div className="h-full bg-slate-200 font-jura text-gray-800  px-5">
            <Container>
                <div className="max-w-[1280px] w-full mx-auto">
                    <AdminMain uploadFile={uploadFile} />
                    <div className="p-5">
                        <div className="mt-10 flex flex-wrap gap-5">
                            <SortItem uploadFile={uploadFile} open={[openItem, setOpenItem]} />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    </>
}

export default ShopAdminPanelPage