import { useEffect, useState } from "react"
import { useMainStore } from "../../store/main"
import _, { keys } from "lodash"
function TelegramBotSettings() {

    const { sort, currentShop, setCurrentShop, order, _keys, setKeys } = useMainStore<any>((state) => state);

    const [key, setKey] = useState("")

    useEffect(() => {
        function sortKeys(obj: any, order: any) {
            let sortedObj: any = {};
            order.forEach((key: any) => {
                if (obj.hasOwnProperty(key)) {
                    sortedObj[key] = obj[key];
                }
            });
            return sortedObj;
        }

        if (sort.length > 0) {
            const itemKeys: string[] = []
            currentShop.items.map((el: any) => {
                const k = Object.keys(el)
                k.map((el: any) => {
                    if (itemKeys.indexOf(el) == -1) {
                        itemKeys.push(el)
                    }
                })
            })
            let o = [...order, ...itemKeys, "cost"];
            o = _.uniq(o)
            const sortedData = currentShop.items.map((item: any) => sortKeys(item, o));
            setCurrentShop({ ...currentShop, items: sortedData })

            return
        }

        if (currentShop.items) {
            const itemKeys: string[] = []
            currentShop.items.map((el: any) => {
                const k = Object.keys(el)
                k.map((el: any) => {
                    if (itemKeys.indexOf(el) == -1) {
                        itemKeys.push(el)
                    }
                })
            })
            let o = [...order, ...itemKeys, "cost"];
            o = _.uniq(o)
            const sortedData = currentShop.items.map((item: any) => sortKeys(item, o));
            setCurrentShop({ ...currentShop, items: sortedData })

            return
        } else if (currentShop.sliceItems) {
            const itemKeys: string[] = []
            currentShop.items.map((el: any) => {
                const k = Object.keys(el)
                k.map((el: any) => {
                    if (itemKeys.indexOf(el) == -1) {
                        itemKeys.push(el)
                    }
                })
            })
            let o = [...order, ...itemKeys, "cost"];
            o = _.uniq(o)
            const sortedData = currentShop.sliceItems.map((item: any) => sortKeys(item, o));
            setCurrentShop({ ...currentShop, sliceItems: sortedData })

            return
        }
    }, [order])

    useEffect(() => {
        
        if (currentShop.keys.indexOf("Цена") == -1) {
            setCurrentShop({ ...currentShop, keys: ["Цена", ...currentShop.keys] })
        }
        if (currentShop.keys.indexOf("Название") == -1) {
            setCurrentShop({ ...currentShop, keys: ["Название", ...currentShop.keys] })
        }


        console.log(currentShop.keys, "KEYS")

    }, [order])

    function viewMainKeys() {
        return <label className="flex my-2">
            <input value={"Название"} className="w-2/3 px-2" type="text" />
            <input value={"Цена"} className="w-1/3 px-2" type="text" />
        </label>
    }

    return <div className="bg-slate-100 p-5 rounded-xl border-gray-400 max-[834px]:w-full  flex flex-col gap-2">
        <label>
            Карточка товара
            {viewMainKeys()}
            <div className="flex flex-col gap-2">
                {(currentShop.keys.length > 0) ? currentShop.keys.map((el: string) => {
                    if (el == "Название" || el == "Цена") return
                    return <section className="flex w-full"><input className="px-2 w-full" onChange={(e) => {
                        const index = currentShop.keys.indexOf(el)
                        const value = e.target.value
                        setCurrentShop({ ...currentShop, keys: [...currentShop.keys.slice(0, index), value, ...currentShop.keys.slice(index + 1)] })
                    }} value={el} type="text" />
                        <input onClick={() => {
                            const index = currentShop.keys.indexOf(el)
                            setCurrentShop({ ...currentShop, keys: [...currentShop.keys.slice(0, index), ...currentShop.keys.slice(index + 1)] })
                        }} className="px-2 bg-white cursor-pointer" type="button" value="-" />
                    </section>
                }) : <span>Доавьте значения для карточки товара.. </span>}
                <section className="w-full">
                    <input
                        className="w-1/2 px-2"
                        value={key}
                        onChange={(e) => setKey(e.target.value)}
                        type="text" />
                    <input
                        onClick={() => {
                            if (currentShop.keys.indexOf(key) == -1) {
                                setCurrentShop({ ...currentShop, keys: [...currentShop.keys, key] })
                                setKey("")
                            }
                        }}
                        className="w-1/2 cursor-pointer px-2 bg-white border border-gray-600" type="button" value="Добавить" />
                </section>
            </div>
        </label>

        {/* <label>Телеграм - Приветствие:
            <input
                value={currentShop.messages["welcome"]}
                onChange={(e) => setCurrentShop({ ...currentShop, messages: { ...currentShop.messages, welcome: e.target.value } })}
                className="w-full resize-none border border-gray-400 outline-none  rounded-sm px-2" />
        </label> */}


        {currentShop.keys.map((el: any) => {
            if (el == "Название" || el == "Цена") return
            return <label>
                Телеграм текст - {el}
                <input
                    value={currentShop.messages[el]}
                    onChange={(e) => setCurrentShop({ ...currentShop, messages: { ...currentShop.messages, [el]: e.target.value } })}
                    className="w-full resize-none border border-gray-400 outline-none  rounded-sm px-2" />
            </label>
        })}
    </div>

}

export default TelegramBotSettings