import { useLocation } from "react-router-dom";
import { useLocalStore } from "../store/local"
import React, { useState } from "react";
import { useMainStore } from "../store/main";

function Card({ data, addr, sub, bot }: { data: any, addr: string, sub: number, bot: string }) {

    const location = useLocation()
    const { basket, setBasket, setBasket_, transactions, addTransaction } = useLocalStore<any>((state) => state);
    const { basketBox, setBasketBox, payBox, setPayBox } = useMainStore<any>(state => state)
    
    const [error, setError] = useState<boolean | null>(null)

    function viewButton(elem: any) {
        const store_name = location.pathname.split("/").at(-1)
        const items = basket.filter((el: any) => el.name === store_name)
        return items.find((el: any) => el.item.id === elem.id)
    }

    function pushItemStore(item: any) {
        const i = basket.map((el: any) => el.id).indexOf(item.id)
        if (i == -1) {
            setBasket({
                id: item.id,
                name: location.pathname.replace("/", ""),
                item: item
            })
        } else {
            const newArray = basket.reduce((acc: any, obj: any) => {
                if ((obj.name == location.pathname.replace("/", "")) && (obj.id !== item.id)) {
                    acc.push(obj);
                }
                return acc;
            }, []);
            setBasket_(newArray)
        }
    }

    function changePayBox(id: number) {
        const idx = basketBox.indexOf(id)
        if (idx !== -1) {
            setBasketBox([...basketBox.slice(0, idx), ...basketBox.slice(idx + 1)])
        } else {
            setBasketBox([...basketBox, id])
        }
    }

    async function pay(data: any, type: string) {
        console.log(data, "DATA")
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-pay`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow": "*"
            },
            body: JSON.stringify({
                user_id: null,
                type_fiat: type,
                amount_rub: data.price ? data.price : data["Цена"],
                address_btc: addr,
                items: data,
                sub: sub,
                bot_name: location.pathname.split("/").at(-1)
            })
        }).then((res: Response) => res.json()).then((d: any) => {
            if (!d.error) {
                setPayBox([...payBox, { ...d, id: data.id }])
                addTransaction({store: location.pathname.split("/")[1], data: data, pay: d})
            } else {
                setError(true)
            }
        }).catch(err => console.error(err))
    }

    function viewPayCard(data: any) {
        const transaction: any = payBox.filter((el: any) => el.id === data.id)
        return <>
            <div className="flex flex-col min-h-[294px] p-5">
                <div className="flex gap-5">
                    <img className="min-w-36 min-h-36 max-w-36 max-h-36 rounded-lg" src={data.image} />
                    <div className="flex flex-col">
                        <span className="break-all">Заявка: {transaction[0].order}</span>
                        <span className="break-all">Реквизиты: {transaction[0].paymentData}</span>
                        <span className="break-all">Сумма: {transaction[0].amountExc}₽</span>
                    </div>
                </div>
                <div className="flex flex-col gap-3 mt-4">
                    <button
                        disabled={transaction[0].order}
                        onClick={() => pay(data, "SBP")}
                        className="w-[340px] rounded-sm bg-slate-400 hover:bg-slate-600 transition duration-700 text-slate-100 py-1 mx-auto">Оплата СБП</button>
                    <button
                        disabled={transaction[0].order}
                        onClick={() => pay(data, "CARD")}
                        className="w-[340px] rounded-sm bg-slate-400 hover:bg-slate-600 transition duration-700 text-slate-100 py-1 mx-auto">Оплата карой</button>
                    <button
                        onClick={() => changePayBox(data.id)}
                        className="w-[340px] rounded-sm bg-slate-400 hover:bg-slate-600 transition duration-700 text-slate-100 py-1 mx-auto">Товар</button>
                </div>
            </div>
        </>
    }

    return <div className="bg-slate-200 border border-slate-400 rounded-xl min-w-[380px] max-w-[380px]">
        {
            basketBox.indexOf(data.id) !== -1
                ? <>
                    {payBox.map((el: any) => el.id).indexOf(data.id) == -1
                        ? <div className="flex flex-col min-h-[294px] p-5">
                            <div className="flex gap-5">
                                <img className="w-36 h-36 rounded-lg" src={data.image} />
                                <div className="flex flex-col">
                                    {Object.keys(data).map((x => {
                                        if ((x == "id") || (x == "image") || (x == "handler")) return
                                        return <span>{x == "city" ? "Город"
                                            : x == "name" ? "Название"
                                                : x == "street" ? "Улица"
                                                    : x === "price" ? "Цена"
                                                        : x}: {data[x]}</span>
                                    }))}
                                </div>
                            </div>
                            <div className="flex flex-col gap-3 mt-4">
                                <button
                                    onClick={() => pay(data, "SBP")}
                                    className="w-[340px] rounded-sm bg-slate-400 hover:bg-slate-600 transition duration-700 text-slate-100 py-1 mx-auto">Оплата СБП</button>
                                <button
                                    onClick={() => pay(data, "CARD")}
                                    className="w-[340px] rounded-sm bg-slate-400 hover:bg-slate-600 transition duration-700 text-slate-100 py-1 mx-auto">Оплата карой</button>
                                <button
                                    onClick={() => changePayBox(data.id)}
                                    className="w-[340px] rounded-sm bg-slate-400 hover:bg-slate-600 transition duration-700 text-slate-100 py-1 mx-auto">Товар</button>

                            </div>
                        </div>
                        : <>{viewPayCard(data)}</>
                    }
                </>
                : <>
                    <div className="flex gap-5 p-5">
                        <img className="w-36 h-36 rounded-lg" src={data.image} />
                        <div className="flex flex-col">
                            {Object.keys(data).map((x => {
                                if ((x == "id") || (x == "image") || (x == "handler")) return
                                return <span>{x == "city" ? "Город"
                                    : x == "name" ? "Название"
                                        : x == "street" ? "Улица"
                                            : x === "price" ? "Цена"
                                                : x}: {data[x]}</span>
                            }))}
                        </div>
                    </div>
                    <div className="flex justify-center gap-3 flex-col w-full my-4">
                        <button
                            onClick={() => {
                                pushItemStore(data)
                            }}
                            className="w-[340px] rounded-sm bg-slate-400 hover:bg-slate-600 transition duration-700 text-slate-100 py-1 mx-auto">
                            {viewButton(data) ? "Товар в корзине" : "В корзину"}
                        </button>
                        <button
                            onClick={() => changePayBox(data.id)}
                            className="w-[340px] rounded-sm bg-slate-400 hover:bg-slate-600 transition duration-700 text-slate-100 py-1 mx-auto">Купить</button>
                    </div>
                </>
        }
    </div>

}

export default Card