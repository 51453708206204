import { create } from "zustand";
import { TypeShop } from "../types";
import { values } from "lodash";


export type User = { 
    id: number;
    login: string;
    roles: boolean;
    secret: string;
}


export const useAdminStore = create((set, get) => ({

    admin: null,
    setAdmin: (value: boolean) => set({admin: value}),

    users: [],
    setUsers: (value: User[]) => set({users: value})
   

    // data: {
    //     data: [],
    //     payments: [],
    //     archive: []
    // },

    // setDataInfo: (value:any) => set({data: {...(get() as any).data, data: value }}),
    // setPaymentsInfo: (value: any) => set({data: {...(get() as any).data, payments: value}}),
    // setArchiveInfo: (value: any) => set({data: {...(get() as any).data, archive: value}})

}))