export const debounce = <T extends (...args: any[]) => any>(
  callback: T,
  waitFor: number
) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: any;
    timeout && clearTimeout(timeout);
    timeout = setTimeout(() => {
      result = callback(...args);
    }, waitFor);
    return result;
  };
};



export function clearStore(
  setAccessToken: any,
  setCurrentShop: any,
  setBasketBox: any,
  setPayBox: any,
  setManager: any,
  setSubShops: any,
  setShop: any,
  setOrders: any,
  setUser: any,
  setLogin: any,
) {
  if (setAccessToken) {
    setAccessToken(null)
  } else if (setCurrentShop) {
    setCurrentShop([])
  } else if (setBasketBox) {
    setBasketBox([])
  } else if (setPayBox) {
    setPayBox([])
  } else if (setManager) {
    setManager([])
  } else if (setSubShops) {
    setSubShops([])
  } else if (setShop) {
    setShop([])
  } else if (setOrders) {
    setOrders([])
  } else if (setUser) {
    setUser(null)
  } else if (setLogin) {
    setLogin(false)
  }



  return

}
