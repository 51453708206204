import { create } from "zustand";
import { TypeShop } from "../types";

export const useMainStore = create((set, get) => ({
    
    counter: 0,
    setCounter: () => set({counter: (get() as any).counter+1}),

    login: null,
    setLogin: (value: boolean) => set({login: value}),
    
    user: null,
    setUser: (value: string) => set({user: value}),

    loader: false,
    setLoader: (value: boolean) => set({loader: value}),

    shops: [],
    subShops: [],
    setShop: (value: TypeShop) => set({shops: value}),
    setSubShops: (value: TypeShop) => set({subShops: value}),
    
    currentShop: null,
    setCurrentShop: (value: any) => set({currentShop: value}),
    pushCurrentShop: (value: any) => set({currentShop: {...(get() as any).currentShop, items:  [...(get() as any).currentShop.items, value ]} }),

    basketBox: [],
    payBox: [],
    setBasketBox: (value: any) => set({basketBox: value}) ,
    setPayBox: (value: any) => set({payBox: value}),

    state: null,
    setState: (value:any) => set({state: value}),

    location_store: null,
    setLocationStore: (value: any) => set({location_store: value}),

    orderId: "",
    setOrderId: (value: string) => set({orderId: value}),

    result: {},
    setResult: (value: any) => set({result: value}),

    manager: [],
    setManager: (value: any) => set({manager: value}),

    orders: [],
    setOrders: (value: any) => set({orders: value}),

    balance: 0,
    setBalance: (value: number) => set({balance: value}),

    isSort: false,
    sort: [],
    setSort: (value: any) => {
        set({isSort: true})
        set({sort: value})
    },
    clearSort: () => {
        set({isSort: false})
        set({sort: []})
    },

    newObject: {},
    setNewObject: (value: any) => set({newObject: value}),

    allKeys: [],
    setAllKeys: (value: string[])  => set({allKeys: value}),

    order: [],
    setOrder: (value: string[]) => set({order: value}),

}))