import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Card from "../../components/card"
import { Link } from "react-router-dom"
import { useLocalStore } from "../../store/local"
import StoreBasketPage from "../store-basket"
import { useMainStore } from "../../store/main"
import StoreOrder from "../store-order"
import StoreFindOrders from "../store-find-orders"

export const TelegramStorePage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [error, setError] = useState<null | boolean>(null)
    const { basket, setBasket } = useLocalStore<any>(state => state)
    const { state, setState, setLocationStore, location_store } = useMainStore<any>(state => state)

    async function findStore() {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Contol-Allow-Origin": "*"
            },
            body: JSON.stringify({
                pathname: location.pathname.split("/")[1]
            })
        }).then((res) => res.json())
            .then(d => setState(d))
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        findStore()
        setLocationStore(location.pathname.replace("/", ""))
        const store = location.pathname.split('/').at(-1)
        if (store?.split("_")[1] !== "bot") {
            navigate("/")
        }
    }, [])

    function lengthArray() {
        const l = basket.filter((el: any) => {
            const pathname = location_store;
            return el.name.replace("/", "") == pathname;
        })
        return l.length
    }

    return <div className="min-h-screen h-full bg-slate-200 w-full mx-auto px-5">
        <div className="flex justify-between py-5">
            <Link to={`${location_store}`} className="text-xl flex items-center">{state && state.name}</Link>
            <div className="flex gap-5">
                <Link to={`${location_store}/find-transactions`}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 -960 960 960" width="36px" fill="black"><path d="m480-240 160-160-56-56-64 64v-168h-80v168l-64-64-56 56 160 160ZM200-640v440h560v-440H200Zm0 520q-33 0-56.5-23.5T120-200v-499q0-14 4.5-27t13.5-24l50-61q11-14 27.5-21.5T250-840h460q18 0 34.5 7.5T772-811l50 61q9 11 13.5 24t4.5 27v499q0 33-23.5 56.5T760-120H200Zm16-600h528l-34-40H250l-34 40Zm264 300Z" /></svg>
                </Link>
                <Link to={`${location_store}/check-order`}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 -960 960 960" width="36px" fill="black"><path d="M240-80q-50 0-85-35t-35-85v-120h120v-560l60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60v680q0 50-35 85t-85 35H240Zm480-80q17 0 28.5-11.5T760-200v-560H320v440h360v120q0 17 11.5 28.5T720-160ZM360-600v-80h240v80H360Zm0 120v-80h240v80H360Zm320-120q-17 0-28.5-11.5T640-640q0-17 11.5-28.5T680-680q17 0 28.5 11.5T720-640q0 17-11.5 28.5T680-600Zm0 120q-17 0-28.5-11.5T640-520q0-17 11.5-28.5T680-560q17 0 28.5 11.5T720-520q0 17-11.5 28.5T680-480ZM240-160h360v-80H200v40q0 17 11.5 28.5T240-160Zm-40 0v-80 80Z" /></svg>
                </Link>
                <div className="flex gap-5">
                    <Link to={`${location_store}/basket`} className="flex items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 -960 960 960" width="36px" fill="black"><path d="M200-80q-33 0-56.5-23.5T120-160v-480q0-33 23.5-56.5T200-720h80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720h80q33 0 56.5 23.5T840-640v480q0 33-23.5 56.5T760-80H200Zm0-80h560v-480H200v480Zm280-240q83 0 141.5-58.5T680-600h-80q0 50-35 85t-85 35q-50 0-85-35t-35-85h-80q0 83 58.5 141.5T480-400ZM360-720h240q0-50-35-85t-85-35q-50 0-85 35t-35 85ZM200-160v-480 480Z" /></svg>
                        <span className="text-xl">({lengthArray()})</span>
                    </Link>
                </div>
            </div>
        </div>
        <div className="flex justify-around gap-5 py-10 flex-wrap">
            {location.pathname.split("/").at(-1) === "basket"
                ? <StoreBasketPage />
                : location.pathname.split("/").at(-1) === "check-order"
                    ? <StoreOrder />
                    : location.pathname.split("/").at(-1) === "find-transactions"
                        ? <StoreFindOrders />
                        : state && state.items.map((el: any) => <Card key={el.id} bot={state.bot_name} sub={state.sub} data={el} addr={state.wallet.address} />)}
        </div>

    </div>

}