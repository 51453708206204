import { useEffect, useState } from "react"
import { useLocalStore } from "../../store/local"
import { useMainStore } from "../../store/main"
import Card from "./card"

function BoxManager({ onClick }: { onClick: any }) {

    const [state, setState] = useState([])

    const { manager, setManager, currentShop, pushCurrentShop } = useMainStore<any>((state) => state)
    const { access_token } = useLocalStore<any>((state) => state)

    async function removeItemArchive(user_id: string, bot_name: string, id: number) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/remove-item-archive`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${access_token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({
                user_id: user_id,
                bot_name: bot_name,
                id: id
            })
        }).then(res => res.json()).then(d => console.log("T- Promise (archive /api/remove-item-archive)"))
    }

    function checkManager() {
        if (manager.length === 0) return false
        const results = []
        manager.map((el:any) => el.archive[0].data.length > 0  ? results.push(...el.archive[0].data) : null ) 
        return results.length > 0 ? true : false

    }

    return <div className="max-h-screen h-full w-full fixed flex top-0 left-0 justify-center items-center bg-[#000]/60">
        <div className="max-w-[820px] w-full rounded-xl bg-white mx-10">
            <div className={`px-8 w-full flex justify-between mt-2`}>
                <span
                    className="flex items-end justify-end  block">
                </span>
                <div id="change_modal" onClick={onClick} >
                    <svg
                        className="h-8 w-8 cursor-pointer translate-x-4" xmlns="http://www.w3.org/2000/svg" fill="#475569" viewBox="0 0 24 24" stroke="#475569" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>
            <div className="px-5 mb-5 flex flex-col max-h-[620px] overflow-y-scroll">
                {checkManager() ? manager.map((el: any, idx: number) => {
                    return <div className="flex flex-col  gap-5 ">
                        <div className="flex flex-wrap justify-around gap-5 ">
                            {(el?.archive?.length > 0 && el?.archive[0]?.data?.length > 0) && el?.archive[0]?.data.map((d: any) => <Card key={d.id} onClick={() => {
                                pushCurrentShop(d)
                                removeItemArchive(manager[idx].user_id, currentShop.bot_name, d.id)
                                const m = manager[idx].archive[0].data.filter((el: any) => el.id !== d.id)
                                setManager([...manager.slice(0, idx), { ...manager[idx], archive: [ {data: m} ] } , ...manager.slice(idx+1)])
                            }} data={d} />)}
                        </div>
                    </div>
                }) : <div className="mx-auto p-5">Пусто..</div>}
            </div>
        </div>
    </div>
}

export default BoxManager