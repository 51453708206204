import { useLocation, useNavigate } from "react-router-dom"
import Authorization from "../../components/authorization"
import { useEffect } from "react"
import { useMainStore } from "../../store/main"
import Container from "../../components/container"

function LoginPage() {
    const location = useLocation()
    const navigate = useNavigate()

    const { login } = useMainStore<{login: boolean}>(state => state as any)

    useEffect(() => {
        if (login && (location.pathname === "/login")) {
            return navigate("/")
        }
    }, [])


    return <div className="bg-slate-200 h-full">
        <div className="flex justify-center">
            <Container>
                <Authorization />
            </Container>
        </div>
    </div>
}

export default LoginPage