type TextBox = {
    num: string;
    title: string;
    desc: string;
    svg: any
}

function TextBox(props: TextBox) {

    return <div
        className="min-w-64 max-w-72 w-full bg-transparent shadow-[0px_0px_15px_rgba(0,0,0,0.09)] p-9 space-y-3 relative overflow-hidden">
        <div className="w-24 h-24 bg-gray-400 rounded-full absolute -right-5 -top-7">
            <p className="absolute bottom-6 left-7 text-white text-2xl">{props?.num}</p>
        </div>
        <div className="fill-gray-500 w-12">
            {props?.svg}
        </div>
        <h1 className="font-bold text-xl">{props?.title}</h1>
        <p className="text-sm text-zinc-500 leading-6">
            {props?.desc}
        </p>
    </div>
}

export default TextBox