import { useEffect, useState } from "react"
import { useMainStore } from "../store/main"
import axios from 'axios';
import * as bitcoin from 'bitcoinjs-lib';

function OutputPayment({ onClick}: { onClick: any}) {

    const {balance} = useMainStore<any>((state) => state)
    const [address, setAddress] = useState("")
    const [sum, setSum] = useState("")
    const [fee, setFee] = useState('')
    const {currentShop} = useMainStore<any>(state => state)
    
    const [txId, setTxId] = useState<string | null>(null);
    const [error, setError] = useState<string>('');

    // const sendBitcoin = async (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    
    //     try {
    //       // Указываем сеть: используем тестовую сеть
    //       const network = bitcoin.networks.testnet; // Для основной сети используйте bitcoin.networks.bitcoin
    
    //       // Декодируем приватный ключ 
    //       //@ts-ignore
    //       const keyPair = bitcoin.ECPair.fromWIF(currentShop.wallet.privateKey, network);
    
    //       // Получаем UTXOs для адреса отправителя
    //       const { data: utxos } = await axios.get(`https://api.blockcypher.com/v1/btc/test3/addresses/${currentShop.wallet.address}?unspentOnly=true`);
    
    //       //@ts-ignore
    //       const txb = new bitcoin.TransactionBuilder(network);
    //       let totalInput = 0;
    
    //       // Добавляем UTXOs в транзакцию
    //       utxos.txrefs.forEach((utxo: { tx_hash: string; tx_output_n: number; value: number }) => {
    //         txb.addInput(utxo.tx_hash, utxo.tx_output_n);
    //         totalInput += utxo.value;
    //       });
    
    //       const amountInSatoshis = parseFloat(sum) * 1e8; // Конвертация BTC в сатоши
    //       const fee = 10000; // Фиксированная комиссия за транзакцию
    //       const change = totalInput - amountInSatoshis - fee;
    
    //       // Проверяем, достаточно ли средств для выполнения транзакции
    //       if (change < 0) {
    //         throw new Error('Недостаточно средств для выполнения транзакции.');
    //       }
    
    //       // Добавляем выходы: адрес получателя и сдачу
    //       txb.addOutput(address, amountInSatoshis);
    //       if (change > 0) {
    //         txb.addOutput(currentShop.wallet.address, change); // Возврат остатка
    //       }
    
    //       // Подписываем все входы
    //       utxos.txrefs.forEach((_:any, index:any) => {
    //         txb.sign(index, keyPair);
    //       });
    
    //       // Получаем закодированную транзакцию
    //       const tx = txb.build();
    //       const txHex = tx.toHex();
    
    //       // Отправляем транзакцию в сеть
    //       const response = await axios.post('https://api.blockcypher.com/v1/btc/test3/txs/push', {
    //         tx: txHex,
    //       });
    
    //       setTxId(response.data.tx.hash); // Получаем идентификатор транзакции
    //       setError('');
    //     } catch (err: any) {
    //       setError('Ошибка отправки: ' + err.message);
    //       setTxId(null);
    //     }
    //   };


    
    return <div className="fixed top-0 left-0 w-full min-h-screen bg-[#000]/80 z-50 flex justify-between items-center">
        <div className="max-w-[520px] w-full bg-slate-100 mx-auto rounded-xl ">
            <div className={` px-8 w-full flex justify-between mt-4`}>
                <span>
                </span>
                <div id="change_modal" onClick={onClick[0]} >
                    <svg
                        className="h-6 w-6 cursor-pointer translate-x-4" xmlns="http://www.w3.org/2000/svg" fill="#475569" viewBox="0 0 24 24" stroke="#475569" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>
            <form className="p-5">
                <label>
                    Баланс: {balance} BTC
                </label>
                <div className="flex">
                    <label className="flex flex-col gap-2 w-2/3">
                        <input 
                            placeholder="btc address" className="outline-none rounded-s-lg py-1 px-2 border border-gray-500 " value={address} onChange={(e) => setAddress(e.target.value)} type="text" />
                    </label>
                    <label className="flex flex-col gap-2 w-1/3">
                        <input placeholder="0.001 (sum)" className="outline-none rounded-r-lg rounded-br-none py-1 px-2 border border-gray-500" value={sum} onChange={(e) => setSum(e.target.value)} type="text" />
                    </label>
                </div>
                <div className="flex w-full justify-end">
                    <label className="flex">
                        <input 
                            placeholder="fee" className="outline-none max-w-[160px] w-full rounded-b-lg py-1 px-2 border border-gray-500 " value={fee} onChange={(e) => setFee(e.target.value)} type="text" />
                    </label>
                </div>
                <div className="flex justify-end mt-5">
                <button className="rounded-lg border border-slate-400 bg-slate-400 transition duration-700 hover:bg-slate-500 text-white px-4 py-1" onClick={(e) => {
                    e.preventDefault()
                    onClick[1](address, sum)
                }}>Вывести</button>
                </div>
            </form>
        </div>
    </div>
}

export default OutputPayment