
function Upload({ onChange, path, id }: { onChange: any, path: string, id: string }) {

    return <div className="flex items-center justify-center w-full bg-transparent max-w-96 max-[625px]:max-w-full w-full  ">

        <label onChange={onChange} htmlFor={id} className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-transparent hover:bg-gray-200 transition duration-300">
            {path ? <img className="max-w-[340px] max-h-[120px]"  src={path} /> :
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg className="w-8 h-8 mb-4 text-slate-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p className="mb-2 text-sm text-slate-500 text-center"><span className="font-semibold">Click to upload</span> or drag and drop (340x120)</p>
                    <p className="w-[80%] break-all text-center mx-auto text-xs text-slate-500">{path ? path : "JPG, JPEG, PNG"}</p>
                </div>}
            <input id={id} type="file" className="hidden" />
        </label>
    </div>
}

export default Upload