import React, { useEffect, useState } from "react"
import { useMainStore } from "../../store/main"
function NewCardHandler({ uploadFile }: { uploadFile: any }) {

    const { newObject, setNewObject, sort, setSort } = useMainStore<any>((state) => state);
    const { currentShop, setCurrentShop } = useMainStore<any>((state) => state);
    const [handler, setHandler] = useState(false);

    return <div className="max-w-[360px] w-full border border-gray-600 rounded-lg p-5">
        {handler ? <div className="flex gap-5 w-full">
            <label onChange={(e) => uploadFile(e, 0, "new-item-image-result")} htmlFor="new-item-image-result" className=" cursor-pointer">
                {newObject["result_image"]
                    ? <img className="min-w-[128px] max-w-[128px] rounded-lg" src={newObject["result_image"]} />
                    : <svg width={128} height={128} className="border-gray-400" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V11" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4 16L8.29289 11.7071C8.68342 11.3166 9.31658 11.3166 9.70711 11.7071L13 15M13 15L15.7929 12.2071C16.1834 11.8166 16.8166 11.8166 17.2071 12.2071L20 15M13 15L15.25 17.25" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round" />
                        <path d="M18 8V3M18 3L16 5M18 3L20 5" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>}
                <input id={"new-item-image-result"} type="file" className="hidden" />
            </label>
            <div className="flex flex-col justify-between h-32">
                <textarea placeholder='desc' onChange={(e) => setNewObject({ ...newObject, result: e.target.value })} className="h-32  bg-slate-100 border border-slate-300 rounded-sm p-2 w-[170px] resize-none overflow-y-scroll outline-none rounded-xl">{newObject["result"]}</textarea>
            </div>
        </div>
            : <div className="flex gap-5 w-full">
                <label htmlFor={"new-item-image"} className="cursor-pointer" onChange={(e) => uploadFile(e, 0, "new-item-image")}  >
                    {newObject["image"]
                        ? <img className="min-w-[128px] max-w-[128px] rounded-lg" src={newObject["image"]} />
                        : <svg width={128} height={128} className="border-gray-400" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 4H8.8C7.11984 4 6.27976 4 5.63803 4.32698C5.07354 4.6146 4.6146 5.07354 4.32698 5.63803C4 6.27976 4 7.11984 4 8.8V15.2C4 16.8802 4 17.7202 4.32698 18.362C4.6146 18.9265 5.07354 19.3854 5.63803 19.673C6.27976 20 7.11984 20 8.8 20H15.2C16.8802 20 17.7202 20 18.362 19.673C18.9265 19.3854 19.3854 18.9265 19.673 18.362C20 17.7202 20 16.8802 20 15.2V11" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4 16L8.29289 11.7071C8.68342 11.3166 9.31658 11.3166 9.70711 11.7071L13 15M13 15L15.7929 12.2071C16.1834 11.8166 16.8166 11.8166 17.2071 12.2071L20 15M13 15L15.25 17.25" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M18 8V3M18 3L16 5M18 3L20 5" stroke="#94a3b8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>}
                    <input id={"new-item-image"} type="file" className="hidden" />
                </label>
                <div className="flex flex-col h-32 overflow-y-scroll justify-between">
                    <div className="flex items-center flex-col mt-1">
                        <div>
                            <input className={`outline-none bg-slate-100 border border-slate-300 rounded-sm px-2 my-0.5`} placeholder={"Название"} onChange={(e) => {
                                setNewObject({ ...newObject, ["Название"]: e.target.value })
                            }} value={newObject["Название"] || ""} type="text" />
                            <input className={`outline-none bg-slate-100 border border-slate-300 rounded-sm px-2 my-0.5`} placeholder={"Цена"} onChange={(e) => {
                                setNewObject({ ...newObject, ["Цена"]: e.target.value })
                            }} value={newObject["Цена"] || ""} type="text" />
                        </div>
                        {currentShop.keys.map((key: string) => {
                            if (key == "Название" || key == "Цена") return
                            return <input key={key} className={`outline-none bg-slate-100 border border-slate-300 rounded-sm px-2 my-0.5`} placeholder={key} onChange={(e) => {
                                setNewObject({ ...newObject, [key]: e.target.value })
                            }} value={newObject[key] || ""} type="text" />
                        })}
                    </div>
                </div>
            </div>}
        <div className="flex">
            <button
                onClick={() => setHandler(!handler)}
                className="mt-5 w-full bg-gray-800 border  border-slate-300 rounded-lg rounded-r-none py-1 bg-slate-100">{handler ? "Продукт" : "Описание"}</button>
            <button
                onClick={() => {
                    if (currentShop.items) {
                        console.log(currentShop.items)
                        setCurrentShop({ ...currentShop, items: [...currentShop.items, { ...newObject, id: Math.ceil(Math.random() * 100000) }] })

                    } else {
                        setCurrentShop({ ...currentShop, sliceItems: [...currentShop.sliceItems, { ...newObject, id: Math.ceil(Math.random() * 100000) }] })
                    }
                    if (sort.length > 0) {
                        setSort([...sort, { ...newObject, id: Math.ceil(Math.random() * 100000) }])
                    }
                }}
                className="mt-5 w-full bg-gray-800 border border-slate-300  rounded-lg py-1 bg-slate-100 rounded-l-none bg-gray-200  py-1 text-gray-800">Добавить</button>
        </div>
    </div>

}

export default NewCardHandler