import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import ShopPage from './pages/shop';
import ShopAdminPanelPage from './pages/shop-admin-panel';
import SupportPage from './pages/support';

import { TelegramStorePage } from './pages/telegram-view';
import { Loader3D } from './components/loader';

import HomePage from './pages/home/index';
import LoginPage from './pages/login/index';
import RegistrationPage from './pages/registration/index';

import { useLocalStore } from './store/local';
import { useMainStore } from './store/main';

import { clearStore } from './utils';


function App() {

  const { loader, setLoader, counter,
    setCurrentShop,
    setBasketBox,
    setPayBox,
    setManager,
    setSubShops,
    setShop,
    setOrders,
    setUser,
    setLogin,
   } = useMainStore<any>(state => state);
  const { access_token, setAccessToken } = useLocalStore<any>(state => state)
  
  useEffect(() => {
    setLoader(true)
    setTimeout(() => {
      setLoader(false)
    }, 1500)
  }, [])

  useEffect(() => {
    get_data()
  }, [counter])

  async function get_data() {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/data/`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${access_token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
    }).then(res => {
      if (res.status === 401) {
        clearStore(
          setAccessToken,
          setCurrentShop,
          setBasketBox,
          setPayBox,
          setManager,
          setSubShops,
          setShop,
          setOrders,
          setUser,
          setLogin,
        )
        setAccessToken(null)
        setLogin(false)
        setShop([])
      }
      return res.json()
    }).then(d => {
      if (d[0]) {
        setShop(d[0].store);
      }
    })
  };

  return (
    <>
      {loader ? <div className="flex justify-center items-center min-h-screen h-full bg-slate-200">
        <Loader3D />
      </div> :
        <>
          <Routes>
            <Route index path='/' element={<HomePage />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/registration' element={<RegistrationPage />} />
            <Route path='/shops' element={<ShopPage />} />
            <Route path='/shops/:id' element={<ShopAdminPanelPage />} />
            <Route path='/support' element={<SupportPage />} />
            {/* <Route path='/admin' element={<AdminPage />} /> */}
            <Route path='*' element={<TelegramStorePage />} />
          </Routes>
        </>
      }
    </>

  );
}

export default App;
