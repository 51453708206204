import React, {useState, useEffect} from "react"
import OutputPayment from "../output_payment"
import { useMainStore } from "../../store/main";

function AdminHeader() {
    const [paymentModal, setPaymentModal] = useState(false)
    const {currentShop, setCurrentShop, balance, setBalance} = useMainStore<any>(state => state)


    const getBalance = async (address: string) => {
        try {
            const response = await fetch(`https://api.blockcypher.com/v1/btc/main/addrs/${address}/balance`);
            if (!response.ok) {
                throw new Error('Ошибка сети');
            }
            const data = await response.json();
            setBalance(data.balance);
        } catch (err) {
            console.log(err)
        }
    };

    async function getPaymentInfo() {
        try {
            const response = await fetch(`${process.env.REACP_APP_API_URL}/api/search-store-orders`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Controll-Allow-Origin": "*"
                },
                body: JSON.stringify({
                    bot_name: currentShop.bot_name
                })
            }).then(res => res.json()).then(d => console.log(d))
         
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getBalance(currentShop?.wallet?.address)
        getPaymentInfo()
    }, [])

    return <>
        {paymentModal && <OutputPayment  onClick={[() => setPaymentModal(false),]} />}

        <div className="px-5 my-3 flex w-1/2 flex-col max-[834px]:w-full">
            <label className="max-w-[600px] min-w-[320px] w-full max-[834px]:max-w-full ">
                Bitcoin Address: <span onClick={() => setPaymentModal(true)} className="text-blue-600 text-underline cursor-pointer">вывести</span>
                <div className="px-4 py-2 rounded-lg border border-slate-300 bg-slate-100 text-nowrap">{currentShop?.wallet && currentShop?.wallet?.address}: {balance + " BTC"}</div>
            </label>
            <label className="gap-5 mt-5 max-w-[600px] min-w-[320px] w-full max-[834px]:max-w-full">
                <a target="_blank" className="text-blue-600" href="https://t.me/BotFather">BotFather</a> Token:
                <input placeholder="Токен BotFather" className="w-full text-lg outline-none bg-slate-100 p-2 border border-slate-300  rounded-lg" onChange={(e) => setCurrentShop({ ...currentShop, telegram_bot_token: e.target.value })} value={currentShop && currentShop.telegram_bot_token} />
            </label>

            <div className="mt-5 flex">
                <input placeholder="Поиск транзакции" className="w-full text-lg outline-none bg-slate-100 p-2 border border-slate-300 rounded-l-lg" onChange={(e) => { }} value={""} />
                <div className="flex">
                    <button className="outline-none bg-slate-100 p-2 border-y border-r border-slate-300">waiting</button>
                    <button className="outline-none bg-slate-100 p-2 border-y border-slate-300">success</button>
                </div>
                <button 
                    className="outline-none bg-slate-100 p-2 border border-slate-300 px-5  rounded-r-lg">Поиск</button>
            </div>
            <div className="h-[152px] mt-1 flex w-full text-lg outline-none bg-slate-100 p-2 border border-slate-300 rounded-lg overflow-y-scroll">

            </div>
        </div>
    </>
}

export default AdminHeader