import { useState, useEffect } from "react"
import { useMainStore } from "../store/main"
function StoreOrder() {

    const {orderId, setOrderId, result, setResult} = useMainStore<any>(state => state);
    const [counter, setCounter] = useState(0);

    async function findResult(orderId: number) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/result-order`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Contol-Allow-Origin": "*"
            },
            body: JSON.stringify({
                order: orderId
            })
        }).then(res => res.json()).then(d => {
            //setResult({ ...result, result_object: d.result })
            //console.log(result)
        })
    }

    useEffect(() => {}, [counter])

    useEffect(() => {}, [result])

    async function checkOrder(orderId: string) {
        const reponse = await fetch(`${process.env.REACT_APP_API_URL}/api/check-order`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Contol-Allow-Origin": "*"
            },
            body: JSON.stringify({
                order: orderId
            })
        }).then(res => res.json()).then((d) => {
            const { data } = d;

            setCounter(counter + 1);
        })
    }

    function viewResult() {
        if (!result?.result_object[0]?.item) return
        return  result?.result_object[0].item.map((el: any) => <div className="mt-5 border border-slate-400 rounded-xl p-5">
            <img className="max-w-[144px] min-w-[144px]" src={el.result_image} />
            <span className="block mt-5">{el.result}</span>
        </div>)
    }

    return <div className="flex gap-5 py-5 flex-wrap w-full">
        <div className="flex justify-center min-[819px]:justify-end min-[819px]:w-[320px] w-full">
            <input placeholder="991060" value={orderId} onChange={(e) => setOrderId(e.target.value)} className="placeholder:text-slate-300 w-full px-1 py-2 text-center bg-transparent border border-white rounded-l-lg outline-none text-gray-600" type="text" />
            <button onClick={() => checkOrder(orderId)} className="px-4 py-1 bg-transparent text-gray-600 rounded-r-lg border border-white">Поиск</button>
        </div>
    </div>

}

export default StoreOrder