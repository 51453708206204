import { useState } from "react"
import InputUI from "./uiverse/input"
import { useLocalStore } from "../store/local"
import { useMainStore } from "../store/main"

export const UserStoreAdd = ({ store }: { store: { bot_name: string } }) => {

    const [state, setState] = useState("")
    const { access_token } = useLocalStore<any>(state => state)
    const { setManager } = useMainStore<any>((state) => state)
    const [error, setError] = useState<string | boolean | null>(null)

    async function create() {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-new-user`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${access_token}`
            },
            body: JSON.stringify({
                bot_name: store.bot_name,
                login: state,

            })
        }).then(res => {
            if (res.status < 306) {
                setError(false)
                setTimeout(() => {
                    setError(null)
                }, 2000)
            } else {
                setError(true)
                setTimeout(() => {
                    setError(null)
                }, 2000)
            }
            return res.json()
        })
    }

    return <div className="w-full max-[834px]:max-w-full flex flex-col w-1/2 items-center px-5">
        <input placeholder="ЛОГИН" className={`${(error !== null) && (error ? "text-red-600" : "text-green-600")}  w-full text-sm text-center outline-none bg-slate-100 p-2 border border-slate-300 rounded-lg`} onChange={(e) => setState(e.target.value)} value={state} />
        <button
            onClick={() => create()}
            type="button" className="text-sm mt-2 py-2 bg-slate-100 hover:bg-gray-400 w-full transition duration-700 w-1/6 rounded-lg p-2 hover:text-gray-100"
        >ДОБАВИТЬ</button>
    </div>
}