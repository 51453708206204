import React, { useEffect, useState } from "react";
import { useMainStore } from "../store/main";
import { useLocation, useNavigate } from "react-router-dom";
import InfoHoverBox from "../components/uiverse/info-hover-box";
import AddHoverBox from "../components/uiverse/add-hover-box";
import ModalAddShop from "../components/modal";
import { useLocalStore } from "../store/local";
import { TypeShop } from "../types";
import Container from "../components/container"

function ShopPage() {

    const navigate = useNavigate();
    const location = useLocation();
    const [modal, setModal] = useState(false);
    const [change, setChnage] = useState(0);

    const { access_token, setAccessToken } = useLocalStore<any>(state => state);
    const { login, shops, setShop, subShops, setSubShops,  setLogin, setCurrentShop, user } = useMainStore<any>(state => state);

    useEffect(() => {
        if (!login) {
            return navigate('/');
        }
        get_data()
        get_stores()
    }, [location.pathname]);

    async function get_data() {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/data/`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${access_token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
        }).then(res => {
            if (res.status === 401) {
                setAccessToken(null)
                setLogin(false)
                setShop([])
            }
            return res.json()
        }).then(d => {
            if (d[0]) {
                setShop(d[0].store);
            }
        })
    };

    async function get_stores() {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-store`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${access_token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({ login: user })
        }).then(res => res.json()).then(d => {
            setSubShops(d.result)
        })
    }

    return <div className="h-full flex flex-col bg-slate-200 font-jura px-5">
        <Container>
            {modal && <div className="fixed left-0 top-0 min-h-screen h-full w-full bg-[#000]/50 z-50 flex items-center justify-center">
                <ModalAddShop c={[change, setChnage]} lastIndex={shops.length || 0} onClick={() => setModal(false)} />
            </div>}
            <div className="max-w-[1280px] flex gap-5 w-full mx-auto flex-wrap">

                {(shops) && shops.map((shop: TypeShop, id: number) => <InfoHoverBox key={id} onClick={() => {
                    setCurrentShop(shop)
                }} linkID={id + 1} name={shop?.name} desc={shop?.description} logo={shop?.logo} />)}
                {(subShops) && subShops.map((shop: TypeShop, id: number) => <InfoHoverBox key={id} onClick={() => {
                    setCurrentShop(shop)
                }} linkID={id + 1} name={shop?.name} desc={shop?.description} logo={shop?.logo} />)}
                <AddHoverBox onClick={() => setModal(true)} />
            </div>
        </Container>
    </div>
}

export default ShopPage