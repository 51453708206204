import Container from "../components/container"

function SupportPage() {
    return <div className="h-full flex flex-col bg-slate-200 font-jura px-5">
        <Container>

        </Container>
    </div>
}

export default SupportPage