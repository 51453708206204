import { persist } from "zustand/middleware";
import { create } from "zustand";

export const useLocalStore = create(
    persist((set, get) => ({
        access_token: null,
        setAccessToken: (value: string) => set({ access_token: value }),

        secret_token: null,
        setSecretToken: (value: string) => set({secret_token: value}),

        basket: [],
        setBasket: (value: {name: string, item: any, id: number}) => set({basket: [...(get() as any).basket , {
            id: value.id,
            name: value.name,
            item: value.item
        }]}),
        setBasket_: (value: any) => set({ basket:  value}),

        transactions: [],

        addTransaction: (value: any) => set({transactions: [...(get() as any).transactions, value]}),
        removeTransaction: (id: number) => set({transactions: [...(get() as any).transactions.slice(0, id), ...(get() as any).transactions.slice(id+1)]}),
        updateTransaction: (value:any, id: number) => set({transactions: [...(get() as any).transactions.slice(0, id), value ,...(get() as any).transactions.slice(id+1)]})
    }),
        {
            name: 'local-storage',
        },
    )
)