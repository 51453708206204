
function Loader() {

    return <div className="w-full gap-x-2 flex justify-center items-center">
        <div
            className="w-5 bg-[#334155] animate-pulse h-5 rounded-full animate-bounce"
        ></div>
        <div
            className="w-5 animate-pulse h-5 bg-[#1e293b] rounded-full animate-bounce"
        ></div>
        <div
            className="w-5 h-5 animate-pulse bg-[#0f172a] rounded-full animate-bounce"
        ></div>
    </div>
}

export default Loader