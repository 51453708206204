import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Registration from "../../components/registration";
import { useMainStore } from "../../store/main";
import Container from "../../components/container"


function RegistrationPage() {

    const location = useLocation()
    const navigate = useNavigate()
    //@ts-ignore
    const { login } = useMainStore(state => state)
    useEffect(() => {
        if (login && (location.pathname === "/registration")) {
            return navigate("/")
        }
    }, [])

    return <div className="bg-slate-200 h-full flex justify-center">
        <Container>
            <Registration />
        </Container>
    </div>
}

export default RegistrationPage